import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { Link, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  createBillingInvoice,
  getScheduleType,
  getBillingDetailsbyId,
  billingInvoiceGetbyCustomer,
  updateBillingInvoice,
} from "../../../redux/slices/dashboard";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

const BillingSetup = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [sentDate, setSentDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());

  const [billingType, setbillingType] = useState(null);
  const [billingDetails, setBillingDetailsData] = useState([]);
  const [customerData, setCustomerData] = useState(null);

  const navigate = useNavigate();
  const param = useParams();
  const [customerId] = useState(param?.customerId || null);
  const [billingNameParam] = useState(param?.billingName || null);
  const [scheduleTypeData, setSchduleTypeData] = useState([]);

  const dispatch = useDispatch();
  const getBillingDetails = () => {
    let params = {
      id: customerId,
    };
    dispatch(
      getBillingDetailsbyId({
        ...params,
        cb(res) {
          if (res?.data) {
            setBillingDetailsData(res?.data?.payload);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const getCutomerData = () => {
    let params = {
      id: customerId,
    };

    dispatch(
      billingInvoiceGetbyCustomer({
        ...params,
        cb(res) {
          if (res?.data) {
            const payloadData = res.data.payload;
            if (Array.isArray(payloadData) && payloadData.length > 0) {
              const responseData = payloadData[0].data;
              setStartDate(new Date(responseData.billing_start_date));
              setEndDate(new Date(responseData.billing_end_date));
              setDueDate(new Date(responseData.due_date));
              setSentDate(new Date(responseData.send_date));
              setbillingType(responseData.billing_type_details.id.toString());

              setCustomerData(responseData);
            
            } else {
              setCustomerData(null);
            }
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    getCutomerData();
    getBillingDetails();
    getSoteddata();
  }, []);

  const handleSave = (e) => {
    e.preventDefault();

    let diffTime = 0;
    let diffDays = 0;
    if (billingType === "1" || billingType === "2" || billingType === "3") {
      diffTime = Math.abs(startDate - endDate);
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (billingType === "1" && diffDays < Number(6)) {
        toast.error(
          "Please select valid start and end date for weekly billing"
        );
        return;
      }
      if (billingType === "2" && diffDays < Number(13)) {
        toast.error(
          "Please select valid start and end date for 2 weeks billing"
        );
        return;
      }
      if (billingType === "3" && diffDays < Number(27)) {
        toast.error(
          "Please select valid start and end date for 4 weeks billing"
        );
        return;
      }
    }

    let params = {
      customer_id: customerId,
      billing_type: billingType,
      billing_start_date: billingType === "4" ? moment(sentDate).format('YYYY-MM-DD'): moment(startDate).format('YYYY-MM-DD'),
      billing_end_date: billingType === "4" ? moment(dueDate).format('YYYY-MM-DD'): moment(endDate).format('YYYY-MM-DD'),
      billing_send_date: billingType === "4" ? moment(sentDate).format('YYYY-MM-DD') : moment(startDate).format('YYYY-MM-DD'),
      billing_due_date: billingType === "4" ? moment(dueDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
    };

    if (
      billingType === "" ||
      billingType === null ||
      billingType === undefined
    ) {
      toast.error("Please select billing type");
      return;
    } else if (moment(startDate).format('YYYY-MM-DD') == null && billingType !== "4") {
      toast.error("Please select start date");
      return;
    }
    //&& billingType!=="4"
    else if (moment(endDate).format('YYYY-MM-DD') == null && billingType !== "4") {
      toast.error("Please select Billing end date");
      return;
    } else if (moment(sentDate).format('YYYY-MM-DD') == null && billingType === "4") {
      toast.error("Please select start date");
      return;
    }
    //&& billingType!=="4"
    else if (moment(dueDate).format('YYYY-MM-DD') == null && billingType === "4") {
      toast.error("Please select Billing end date");
      return;
    } else if (moment(startDate).format('YYYY-MM-DD') > moment(endDate).format('YYYY-MM-DD')) {
      toast.error(
        " Please select valid dates - End Date should be grater then start date"
      );
      return;
    } else if (moment(sentDate).format('YYYY-MM-DD') > moment(dueDate).format('YYYY-MM-DD')) {
      toast.error(
        " Please select valid dates - Due Date should be grater then sent date"
      );
      return;
    } else {
      if (customerData !== null) {
        let params = {
          invoice_id: customerData?.id,
          billing_type: billingType,
          billing_start_date: billingType === "4" ? moment(sentDate).format('YYYY-MM-DD'): moment(startDate).format('YYYY-MM-DD'),
        billing_end_date: billingType === "4" ? moment(dueDate).format('YYYY-MM-DD'): moment(endDate).format('YYYY-MM-DD'),
        billing_send_date: billingType === "4" ? moment(sentDate).format('YYYY-MM-DD') : moment(startDate).format('YYYY-MM-DD'),
        billing_due_date: billingType === "4" ? moment(dueDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
        };

        dispatch(
          updateBillingInvoice({
            ...params,
            cb(res) {
              if (res?.data) {
                if (billingType === "4") {
                  navigate(`/manualbill/${customerId}`);
                } else {
                  navigate(`/autobill/${customerId}`);
                }
              }
            },
          })
        );
      } else {
        dispatch(
          createBillingInvoice({
            ...params,
            cb(res) {
              if (res.status) {
                if (billingType === "4") {
                  navigate(`/manualbill/${customerId}`);
                } else {
                  navigate(`/autobill/${customerId}`);
                }
              }
            },
          })
        );
      }
    }
  };

  const handleBillingType = (id) => {
    setbillingType(id);
    setStartDate(null);
    setEndDate(null);
  };

  const getSoteddata = () => {
    dispatch(
      getScheduleType({
        cb(res) {
          if (res.data) {
            setSchduleTypeData(res?.data?.payload);
          } else {
            toast.error("Something went wrong");
          }
        },
      })
    );
  };
  const reset = () => {
    if (customerData !== null) {
      getCutomerData();
      getBillingDetails();
    } else {
      setbillingType("");
      setStartDate("");
      setEndDate("");
    }
  };
  const getEndMinDate = () => {
    let endMinDate = new Date();
    if (billingType === "1") {
      endMinDate = new Date(startDate);
      endMinDate.setDate(endMinDate.getDate() + 6);
    } else if (billingType === "2") {
      endMinDate = new Date(startDate);
      endMinDate.setDate(endMinDate.getDate() + 13);
    } else if (billingType === "3") {
      endMinDate = new Date(startDate);
      endMinDate.setDate(endMinDate.getDate() + 27);
    }
    return endMinDate;
  };

  const scheduleTypeDataList =
    customerData !== null && billingType !== "4"
      ? scheduleTypeData.filter((data) => data.id.toString() !== "4")
      : scheduleTypeData;

  return (
    <>
      <div className="SetupBill">
        <div className="backbutton mt-3">
          <div className="button_main">
            <Link
              // to={`/manualbill/${customerId}`}
              to={
                billingNameParam === "manual"
                  ? `/manualbill/${customerId}`
                  : "" || billingNameParam === "automatic"
                  ? `/autobill/${customerId}`
                  : "" || billingNameParam === ""
                  ? `/dashboard`
                  : ""
              }
              className="back_btn"
            >
              <img
                src={Images.BackButton}
                alt="backbutton"
                className="backBtnIcon"
              />
              Go Back
            </Link>
            <h5 className="pageHeading d-block">
              Setup Billing of {billingDetails?.customer_name}
            </h5>
          </div>
          <div className="button_box">
            <button
              type="button"
              onClick={(e) => reset(e)}
              className="Discard_btn"
            >
              Discard
            </button>
            <button
              type="button"
              onClick={(e) => handleSave(e)}
              className="save_btn"
            >
              Save
            </button>
          </div>
        </div>
        <div className="editprofile">
          <div className="profileBox">
            <h5 className="pageHeading d-block">
              {" "}
              Billing Details of {billingDetails?.customer_name}
            </h5>
            <div className="row">
              <div className="col-lg-2">
                <h4 className="billingDetails">Bill To</h4>
                <h5 className="customerName">
                  {billingDetails?.customer_name}
                </h5>
                <p className="customerAddress">
                  {billingDetails?.bill_to?.City}{" "}
                  {billingDetails?.bill_to?.PostalCode}
                </p>
              </div>
              <div className="col-lg-2 offset-1">
                <h4 className="billingDetails">Ship To</h4>
                <h5 className="customerName">
                  {billingDetails?.customer_name}
                </h5>
                <p className="customerAddress">
                  {billingDetails?.bill_to?.CountrySubDivisionCode}{" "}
                  {billingDetails?.bill_to?.Line1}
                </p>
              </div>
              <div className="col-lg-6 offset-1">
                <div className="row">
                  <div className="col-lg-12">
                    <div class="form-group mb-3">
                      <label class="input_label" for="uname">
                        Billing Type
                      </label>
                      {/* <input type="text" value= {getCustomerDetail?.billing_type_details?.name} name="password" className="form-control input_box " /> */}
                      {/* <img
                        src={Images.ArrowDown}
                        alt="LeftIcon"
                        className="BillIcon"
                      /> */}

                      {
                        <select
                          className="filter_box"
                          onChange={(e) => handleBillingType(e.target.value)}
                          disabled={
                            customerData !== null && billingType === "4"
                          }
                          value={billingType}
                        >
                          <option value=""> All</option>
                          {scheduleTypeDataList.map((data, index) => (
                            <option
                              key={index}
                              value={data.id}
                              className="filter_items"
                            >
                              {data.name}
                            </option>
                          ))}
                        </select>
                      }
                    </div>
                  </div>

                  {billingType !== "4" ? (
                    <>
                      <div className="col-lg-6">
                        <div class="form-group mb-3">
                          <label class="input_label" for="uname">
                            Billing Start Date{" "}
                          </label>
                          {/* <input type="Date" placeholder="1 March, 2023" name="password" className="form-control input_box " /> */}
                          <DatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                            }}
                            dateFormat="MM/dd/yyyy"
                            minDate={moment().toDate()}
                            placeholderText="MM/DD/YYYY"
                            className="form-control input_box "
                          />
                          <img
                            src={Images.Calendar}
                            alt="LeftIcon"
                            className="BillIcon"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div class="form-group mb-3">
                          <label class="input_label" for="uname">
                            Billing End Date{" "}
                          </label>
                          <DatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            selected={endDate}
                            onChange={(date) => {
                              setEndDate(date);
                            }}
                            dateFormat="MM/dd/yyyy"
                            minDate={getEndMinDate() || moment().toDate()}
                            placeholderText="MM/DD/YYYY"
                            className="form-control input_box "
                          />
                          <img
                            src={Images.Calendar}
                            alt="LeftIcon"
                            className="BillIcon"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-lg-6">
                        <div class="form-group mb-3">
                          <label class="input_label" for="uname">
                            Sent Date{" "}
                          </label>
                          <DatePicker
                            selected={sentDate}
                            onChange={(date) => {
                              setSentDate(date);
                            }}
                            dateFormat="MM/dd/yyyy"
                            minDate={moment().toDate()}
                            placeholderText="MM/DD/YYYY"
                            className="form-control input_box "
                          />
                          <img
                            src={Images.Calendar}
                            alt="LeftIcon"
                            className="BillIcon"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div class="form-group mb-3">
                          <label class="input_label" for="uname">
                            Due Date{" "}
                          </label>
                          <DatePicker
                            selected={dueDate}
                            onChange={(date) => {
                              setDueDate(date);
                            }}
                            dateFormat="MM/dd/yyyy"
                            minDate={sentDate || moment().toDate()}
                            placeholderText="MM/DD/YYYY"
                            className="form-control input_box "
                          />
                          <img
                            src={Images.Calendar}
                            alt="LeftIcon"
                            className="BillIcon"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingSetup;
