import React, { useState } from 'react'
import * as Images from "../../../utilities/images";
import { changePassword } from '../../../redux/slices/auth';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';

const ChangePassword = (props) => {

    const [oldPass, setOldPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [passwordOldShown, setOldPasswordShown] = useState(false);
    const [passwordNewShown, setNewPasswordShown] = useState(false);
    const [passwordConfirmShown, setConfirmPasswordShown] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!oldPass) {
            toast.error("Please enter old password");
            return;
        }
        else if (!newPass) {
            toast.error("Please enter New password");
            return;
        } 
        else if(!confirmPass){
            toast.error("Please enter confirm password");
            return;
        }
        else if (
            !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
                newPass
            )
        ) {
            toast.error(
                "Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character"
            );
            return;
        }
        else if (newPass !== confirmPass) {
            toast.error("New password and confirm password must match");
            return;
        }
        else if(oldPass == newPass){
            toast.error("Old password and new password should not be same");
            return;
        }
        let params = {
            old_password: oldPass,
            new_password: newPass,
            confirm_password: confirmPass,
        }
        dispatch(changePassword({
            ...params, cb(res) {
                if (res) {
                    props.close();
                }
                else {
                }
            }
        }))
    }

    const toggleOldPasswordVisiblity = () => {
        setOldPasswordShown(passwordOldShown ? false : true);
    }
    const toggleNewPasswordVisiblity = () => {
        setNewPasswordShown(passwordNewShown ? false : true);
    }
    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(passwordConfirmShown ? false : true);
    }

    return (
        <>
            <form>
                <div class="form-group mb-4">
                    <label class="input_label" for="uname">Old Password</label>
                    <input type={passwordOldShown ? "text" : "password"} placeholder="Old Password" name="Oldpassword" value={oldPass} onChange={(e) => setOldPass(e.target.value)} className="form-control input_box " />
                    <img src={Images.Lock} alt='LeftIcon' className="inputIcon" />
                    <span className="toggle_password_ info_icon" onClick={() => { toggleOldPasswordVisiblity(!passwordOldShown) }}>
                        <span className={passwordOldShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id="old"></span>
                    </span>
                </div>
                <div class="form-group mb-4">
                    <label class="input_label" for="uname">New Password</label>
                    <input type={passwordNewShown ? "text" : "password"} placeholder="New Password" name="Newpassword" value={newPass} onChange={(e) => setNewPass(e.target.value)} className="form-control input_box " />
                    <img src={Images.Lock} alt='LeftIcon' className="inputIcon" />
                    <span className="toggle_password_ info_icon" onClick={() => { toggleNewPasswordVisiblity(!passwordNewShown) }}>
                        <span className={passwordNewShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id="new"></span>
                    </span>
                </div>
                <div class="form-group mb-4">
                    <label class="input_label" for="uname">Confirm Password</label>
                    <input type={passwordConfirmShown ? "text" : "password"} placeholder="Confirm Password" name="Confirmpassword" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} className="form-control input_box " />
                    <img src={Images.Lock} alt='LeftIcon' className="inputIcon" />
                    <span className="toggle_password_ info_icon" onClick={() => { toggleConfirmPasswordVisiblity(!passwordConfirmShown) }}>
                        <span className={passwordConfirmShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id="confirm"></span>
                    </span>
                </div>
                <div className='modal_btn w-70'>
                    <button onClick={(e) => handleSubmit(e)} class="submit_btn w-100" type="submit" ><span className="submit_text">Change</span></button>
                </div>
            </form>

        </>
    )
}

export default ChangePassword