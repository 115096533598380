import { Route, Routes } from "react-router-dom";
import * as Containers from "../app/containers";
import * as Layouts from "../app/layouts"
import PrivateCheck from "../app/layouts/PrivateCheck";

const Router = () => {
    return (
        <>
            <Routes>
                <Route element={<Layouts.MainLayout />}>
                    <Route path="/dashboard" element={<Containers.AccountDashboard />} />
                    <Route path="/viewedit" element={<Containers.Viewedit />} />
                    <Route path="/autobill/:id" element={<Containers.AutomaticBilling />} />
                    <Route path="/setupbill/:customerId/:billingName?" element={<Containers.BillingSetup/>} />
                    <Route path="/manualbill/:id" element={<Containers.ManualBilling/>} />
                    <Route path="/pagination" element={<Containers.Pagination/>} />
                </Route>
                <Route element={<Layouts.AuthLayout />}>
                    <Route path="/" element={<Containers.Login />} />
                    <Route path="/forgotpassword" element={<Containers.forgotPassword />} />
                    <Route path="/enterotp" element={<Containers.Enterotp />} />
                    <Route path="/recoverpassword" element={<Containers.recoverPassword />} />
                </Route>
                <Route path="/redirectUrl" element={<Containers.RedirectPage/>} />
            </Routes>
        </>
    );
}

export default Router;