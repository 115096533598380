import React,{useState}from 'react';
import * as Images from "../../../utilities/images";
import { forgotPass } from '../../../redux/slices/auth';
import { Link, useNavigate,useLocation  } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';


const ForgotPassword = () => {
    const [useremail,setEmail]=useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    //let user_id = location?.state?.id;


    //form submit
    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (!useremail) {
            toast.error("Please enter email address");
            return;
        }
        else if (useremail && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(useremail)) {
            toast.error("Please enter valid email address");
            return;
        }

        let params = {
            email:useremail,
            
        }
        dispatch(forgotPass({
            ...params, cb(res) {
                if (res) {   
                    navigate('/enterotp', { state: { email : useremail } });
                    localStorage.setItem("email", useremail )
                }
                else {
                    navigate("/")
                }
            }
        }))
    }
  

    return (
        <>
            <div className='login'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="logo_icon">
                            <img src={Images.logo} alt='Location' className='logo_img' />
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <img src={Images.LoginLeft} alt='LeftIcon' className="LoginLeft" />
                        <p className="copyright mainCopyright">© Copyright 2023 all rights reserved.</p>
                    </div>
                    <div className="col-lg-5">
                        <div className="login_details">
                            <h1 className="login_ mb-5">Forgot Password</h1>
                            {/* <p className="welcome_note mb-4">Enter the email address that associated with your
                                account.</p> */}
                            <form id="form">
                                <div className="form-group mb-4">
                                    <label className="input_label " for="uname">Email</label>
                                    <input type="email" placeholder="Email Address" name="email" value={useremail} onChange={(e)=>setEmail
                                    (e.target.value)} className="form-control input_box " />
                                    <img src={Images.Message} alt='LeftIcon' className="inputIcon" />
                                </div>
                                <button class="submit_btn w-100 my-3" type="submit" onClick={(e) => { handleSubmit(e) }}
                                >
                                    {/* <Link to="/enterotp" className="submit_text">Continue</Link> */}
                                Continue
                                </button>
                                <p class="forget_password text-center"><Link to='/'>
                                <img src={Images.backIcon} alt='LeftIcon' className="backIcon" />Back to Login</Link></p>
                            </form>
                        </div>
                        <p className="copyright mobileCopyright text-center">© Copyright 2023 all rights reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword