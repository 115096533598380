import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    allPost: {},
    allDashboardData : {},
    createCustomers: {},
    createBillingInvoice : {},
    billingInvoiceGetbyCustomer: {},
    getUrl:{},
    loading: false
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: ({
        allCustomerList: (state) => {
            state.loading = true
        },
        setallCustomerList: (state, action) => {
            state.loading = false
            state.allCustomerList = action.payload
        },
        
        getBillingDetailsbyId: (state) => {
            state.loading = true
        },
        setBillingdetailsById: (state, action) => {
            state.loading = false
            state.getBillingDetailsbyId= action.payload
            
        },
        setGridPerPageRecord: (state, action) => {
            state.gridPerPageSize = state.perPageSize;
        },
        getBillingInvoiceById: (state) => {
            state.loading = true
        },
        setBillingInvoiceById: (state, action) => {
            state.loading = false
            state.getBillingInvoiceById = action.payload
        },
        createBillingInvoice:(state)=>{
            state.loading=true
        },
        setBillingInvoice:(state,action)=>{
            state.loading=false
            state.createBillingInvoice = action.payload
        },
        createManuallInvoice:(state)=>{
            state.loading=true
        },
        setManuallInvoice:(state,action)=>{
            state.loading=false
            state.createManuallInvoice=action.payload
        },
        creteActivity:(state)=>{
            state.loading=true
        },
        setActivity:(state,action)=>{
            state.loading=false
            state.creteActivity=action.payload
        },
        updateBillingInvoice:(state)=>{
            state.loading=true
        },
        setUpdateBillingInvoice:(state,action)=>{
            state.loading=false
            state.updateBillingInvoice=action.payload
        },
        // getActivityByInvoiceId:(state)=>{
        //     state.loading=true
        // },
        // setActivityByInvoiceId:(state)=>{
        //     state.loading=false
        //     state.getActivityByInvoiceId=action.payload
        // },
        getActivityByInvoiceId:(state)=>{
            state.loading=true
        },
        setActivityByInvoiceId:(state,action)=>{
            state.loading=false
            state.getActivityByInvoiceId=action.payload
        },
        allDashboardData: (state) => {
            state.loading = true
        },
        setAllDashboardData: (state, action) => {
            state.loading = false
            state.allDashboardData = action.payload
        },
        getScheduleType: (state) => {
            state.loading = true
        },
        setGetScheduleType: (state, action) => {
            state.loading = false
            state.allPost = action.payload
        },
        createActivity: (state) => {
            state.loading = true
        },
        setCreateActivity: (state, action) => {
            state.loading = false
            state.createActivity = action.payload
        },
        createCustomers: (state) => {
            state.loading = true
        },
        setCreateCustomers: (state, action) => {
            state.loading = false
            state.createCustomers = action.payload
        },
        billingInvoiceGetbyCustomer: (state) => {
            state.loading = true
        },
        setBillingInvoiceGetbycustomer: (state, action) => {
            state.loading = false
            state.billingInvoiceGetbyCustomer = action.payload
        },
        
        userPostLike: (state) => {
            state.loading = true
        },
        setUserPostLike: (state, action) => {
            state.loading = false
            state.allPost = action.payload
        },
        userPostComment: (state) => {
            state.loading = true
        },
        setUserPostComment: (state, action) => {
            state.loading = false
            state.allPost = action.payload
        },
        imageUplaod: (state) => {
            state.loading = true
        },
        allPostComment: (state) => {
            state.loading = true
        },
        setAllPostComment: (state) => {
            state.loading = false
        },
        setImageUplaod: (state, action) => {
            state.loading = false
        },
        GetUrl:(state)=>{
            state.loading=true
        },
        setGetUrl:(state,action)=>{
            state.loading=false
            state.getUrl=action.payload
        },
        updateActivity:(state)=>{
            state.loading=true
        },
        setUpdateActivity:(state,action)=>{
            state.loading=false
            state.updateActivity=action.payload
        },
        deleteActivity:(state)=>{
            state.loading=true
        },
        setDeleteActivity:(state,action)=>{
            state.loading=false
            state.deleteActivity=action.payload
        },
        GetToken:(state)=>{
            state.loading=true
        },
        setGetToken:(state,action)=>{
            state.loading=false
            state.getToken = action.payload
        },
        updateBillingInvoice:(state)=>{
            state.loading=true
        },
        setUpdateBillingInvoice:(state,action)=>{
            state.loading=false
            state.updateBillingInvoice = action.payload
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        },
       
    }),
})

// Action creators are generated for each case reducer function
export const { allCustomerList,
    billingInvoiceGetbyCustomer,
    updateActivity,
    updateBillingInvoice,
    setUpdateBillingInvoice,
    setUpdateActivity,
    deleteActivity,
    setDeleteActivity,
    setBillingInvoiceGetbycustomer,
     setallCustomerList,
     createBillingInvoice,
     creteActivity,
     setBillingInvoice, 
     setBillingdetailsById,
     getBillingDetailsbyId ,
     getScheduleType,
     setGetScheduleType,
     allDashboardData,
      setAllDashboardData,
      getBillingInvoiceById,
      setBillingInvoiceById,
      getActivityByInvoiceId,
      setActivityByInvoiceId,
      setCreateActivity,
      userPost, 
      setUserPost,
      GetUrl,
      setGetUrl,
      GetToken,
      setGetToken,
      createManuallInvoice,
      setManuallInvoice,
      createCustomers,
      setCreateCustomers,
       userPostLike, setUserPostLike, userPostComment, setUserPostComment, imageUplaod, setImageUplaod, allPostComment, setAllPostComment,  onErrorStopLoad } = dashboardSlice.actions

export default dashboardSlice.reducer