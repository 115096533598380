import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import {
  GetUrl,
  allCustomerList,
  createCustomers,
} from "../../../redux/slices/dashboard";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { getScheduleType } from "../../../redux/slices/dashboard";
import ReactPaginate from "react-paginate";
import moment from "moment-timezone";
import { useDashboardSelector } from "../../../redux/selector/dashboard";
import swal from "sweetalert";

const AccountDashboard = () => {


  const dashboardSelector = useDashboardSelector();
  const [option, setoption] = useState("All");
  const [customerList, setCustomerList] = useState([]);
  const [getUrl, setGeturl] = useState("");
  const [createCustomer, setCreateCustomer] = useState([]);
  const [scheduleTypeData, setSchduleTypeData] = useState([]);
  const [sortById, setSortById] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageSize, setPerPageSize] = useState(10);

  const dispatch = useDispatch();

  const getAllCustomersList = (
    page = currentPage,
    limit = perPageSize,
    id = sortById,
    search = searchVal
  ) => {
    let params = {
      page: page,
      limit: limit,
      search: search,
    };
    if (Boolean(id)) {
      params = { ...params, billing_type: id };
    }
    if (Boolean(search)) {
      params = { ...params, search: search };
    }
    dispatch(
      allCustomerList({
        ...params,
        cb(res) {
          if (res?.data) {
            setTotalPages(res?.data?.payload?.total_pages);
            setTotalCount(res?.data?.payload?.total);
            // setCurrentPage(res?.data?.payload?.current_page);
            // setPerPageSize(res?.data?.payload?.per_page);
            setCustomerList(res?.data?.payload?.billingList);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  // handle change filter

  const handleChangeSort = (id, name) => {
    console.log(id, "idididididididididididid");
    setoption(name);
    setSortById(id);
    getAllCustomersList(currentPage, perPageSize, id);
  };

  // const handleFilter = (value) => {
  //   setoption(value);
  //   getAllCustomersList(currentPage, perPageSize, value);
  // };

  const getSoteddata = () => {
    dispatch(
      getScheduleType({
        cb(res) {
          if (res.data) {
            setSchduleTypeData(res?.data?.payload);
          } else {
            toast.error("Something went wrong");
          }
        },
      })
    );
  };

  const handleSync = () => {
    dispatch(
      createCustomers({
        cb(res) {
          if (res.payload && res.payload.status == "Token expired") {
            swal({
              className: "sweetAlertDelete",
              title: "Session expired! Please Refresh Token",
              icon: "warning",
              dangerMode: true,
              buttons: true,
            }).then((isUrl) => {
              if (isUrl) {
                dispatch(
                  GetUrl({
                    cb(res) {
                      if (res?.data) {
                        const url = res.data.payload;
                        // window.open(url);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("target", "_blank");
                        // Append to html link element page
                        document.body.appendChild(link);
                        // Start download
                        link.click();
                        // Clean up and remove the link
                        link.parentNode.removeChild(link);
                      } else {
                        toast.error("something went wrong");
                      }
                    },
                  })
                );
              }
            });
          }
          else {
            getAllCustomersList();
          }
        },
      })
    );
  };

  useEffect(() => {
    // handleFilter();
    getSoteddata();
    getAllCustomersList();
  }, []);

  const handlePageClick = (selectedItem) => {
    const selectedPage = selectedItem.selected + 1;
    setCurrentPage(selectedPage);
    getAllCustomersList(selectedPage, perPageSize, sortById);
  };

  const handleChangePages = (e) => {
    setPerPageSize(e.target.value);
    getAllCustomersList(currentPage, e.target.value, sortById);
  };

  let pagingDataLabel = perPageSize * currentPage;
  pagingDataLabel = pagingDataLabel > totalCount ? totalCount : pagingDataLabel;

  return (
    <>
      {/*---- Start Customer Searchbar ----*/}
      <section className="dashBoardMain">
        <div className="CustomersSearch my-3">
          <div className="row w-100">
            <div className="col-lg-6 col-md-6">
              <div className="cusomer_box">
                <h6 className="customerHeading">Customers</h6>
                <div className="searchBox_">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={searchVal}
                    onChange={(e) => {
                      setSearchVal(e.target.value);
                      getAllCustomersList(1, 10, sortById, e.target.value);
                    }}
                  />
                  <img
                    src={Images.Search}
                    alt="search"
                    className="img-fluid searchIcon"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="cusomer_box">
                <h6 className="filterHeading text-end">Sort By:</h6>
                <div className="dropdown">
                  {/* <select
                    className="filter_box"
                    onChange={(e) => handleChangeSort(e.target.value)}
                  >
                    <option value=""> All</option>
                    {scheduleTypeData.map((data, index) => (
                      <option
                        key={index}
                        value={data.id}
                        className="filter_items"
                      >
                        {data.name}
                      </option>
                    ))}
                  </select> */}
                  <div className="dropdown">
                    <button className="filter_box dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      {option}
                    </button>
                    <ul
                      className="filter_menu dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li onClick={() => handleChangeSort("", "All")} className="filterMain" ><a className="filter_item" >All</a></li>
                      {scheduleTypeData.map((data, index) => (
                        <li  onClick={() => handleChangeSort(data.id, data.name)} key={index}
                          value={data.id} className="filterMain" ><a className="filter_item" ><span className={data?.name == "2 Weeks" ? "twoWeekdot" : data?.name == "4 Weeks"? "fourWeeklydot" : data?.name == "Weekly"? "weeklydot" : data?.name == "Manual"? "Manual" : ""}></span>{data.name}</a></li>
                      ))}
                    </ul>
                  </div>

                </div>
                <button onClick={(e) => handleSync(e)} className="sync_btn">
                  <img
                    src={Images.Sync}
                    alt="sync"
                    className="img-fluid syncIcon"
                  />
                  Sync
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*---- End Customer Searchbar ----*/}

      <div className="table-responsive CustomerBox mt-3">
        <table id="tableCustomer" className="table_Customer">
          <thead>
            <tr>
              <th className="headingCustomer">Name</th>
              <th className="headingCustomer">Current Billing Period</th>
              <th className="headingCustomer">Billing Type</th>
              <th className="headingCustomer">Previous Billing period </th>
              <th className="headingCustomer">Invoice Status</th>
              <th className="headingCustomer">Action</th>
            </tr>
          </thead>
          <tbody>
            {customerList?.length > 0
              ? customerList?.map((data, index) => {
                console.log(data?.billing_type_details?.name, "REEWREWR");
                return (
                  <tr key={data.customer_id} className="activities_user ">
                    <td className="subheadingCustomer">
                      <img
                        src={Images.User}
                        alt="LeftIcon"
                        className="userIcon"
                      />
                      {data.customer_name}
                    </td>
                    {data?.billing_type_details?.name === "Manual" ? <td className="subheadingCustomer">

                      {Boolean(
                        data?.billingInvoiceDetails[0]?.send_date
                      )
                        ? moment(
                          data?.billingInvoiceDetails[0]?.send_date
                        ).format("D MMM, YYYY")
                        : ""}
                      {` -`}
                      {Boolean(data?.billingInvoiceDetails[0]?.due_date)
                        ? moment(
                          data?.billingInvoiceDetails[0]?.due_date
                        ).format("D MMM, YYYY")
                        : ""}
                    </td> :
                      <td className="subheadingCustomer">

                        {Boolean(
                          data?.billingInvoiceDetails[0]?.billing_start_date
                        )
                          ? moment(
                            data?.billingInvoiceDetails[0]?.billing_start_date
                          ).format("D MMM, YYYY")
                          : ""}
                        {` -`}
                        {Boolean(data?.billingInvoiceDetails[0]?.send_date)
                          ? moment(
                            data?.billingInvoiceDetails[0]?.send_date
                          ).format("D MMM, YYYY")
                          : ""}
                      </td>
                    }


                    <td className="subheadingCustomer">
                      {Boolean(data?.billing_type_details?.name) ? (
                        <>
                            <span className={data?.billing_type_details?.name == "2 Weeks" ? "twoWeekdot" : data?.billing_type_details?.name == "4 Weeks"? "fourWeeklydot" : data?.billing_type_details?.name == "Weekly"? "weeklydot" : data?.billing_type_details?.name == "Manual"? "Manual" : "All"}></span>
                            {data?.billing_type_details?.name}
                        </>
                      ) : (
                        "-"
                      )}
                    </td>

                    {data?.billing_type_details?.name === "Manual" ? <td className="subheadingCustomer">

                      {Boolean(
                        data?.billingInvoiceDetails[1]?.send_date
                      )
                        ? moment(
                          data?.billingInvoiceDetails[1]?.send_date
                        ).format("D MMM, YYYY")
                        : ""}
                      {` -`}
                      {Boolean(data?.billingInvoiceDetails[1]?.due_date)
                        ? moment(
                          data?.billingInvoiceDetails[1]?.due_date
                        ).format("D MMM, YYYY")
                        : ""}
                    </td> :
                      <td className="subheadingCustomer">

                        {Boolean(
                          data?.billingInvoiceDetails[1]?.billing_start_date
                        )
                          ? moment(
                            data?.billingInvoiceDetails[1]?.billing_start_date
                          ).format("D MMM, YYYY")
                          : ""}
                        {` -`}
                        {Boolean(data?.billingInvoiceDetails[1]?.send_date)
                          ? moment(
                            data?.billingInvoiceDetails[1]?.send_date
                          ).format("D MMM, YYYY")
                          : ""}
                      </td>
                    }
                    {/* <td className="subheadingCustomer">
                        {Boolean(data?.billing_type_details?.name) ? (
                          <>
                            <span className="weeklydot"></span>
                            {data?.billing_type_details?.name}
                          </>
                        ) : (
                          "-"
                        )}
                      </td> */}
                    <td className="subheadingCustomer">
                      {data?.billingInvoiceDetails[0]?.status === "send" ? (
                        <p className="sentBox">
                          {data?.billingInvoiceDetails[0]?.status}
                        </p>
                      ) : (
                        ""
                      )}
                      {data?.billingInvoiceDetails[0]?.status ===
                        "pending" ? (
                        <p className="PendingBox">
                          {data?.billingInvoiceDetails[0]?.status}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="subheadingCustomer">
                      <Link
                        to={
                          data?.billing_type_details?.name === "Manual"
                            ? `/manualbill/${data.customer_id}`
                            : `/autobill/${data.customer_id}`
                        }
                      >
                        <button type="button" className="reviewBtn">
                          Review
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })
              : "No cutomers Found"}
          </tbody>
        </table>
      </div>
      {/*---- Start mobile Customer Searchbar ----*/}

      <div className="CustomersSearchMobile my-3">
        <div className="row w-100">
          <div className="col-lg-6 col-md-6">
            <div className="cusomer_box">
              <h6 className="customerHeading">Customers</h6>
              <button onClick={(e) => handleSync(e)} className="sync_btn">
                <img
                  src={Images.Sync}
                  alt="sync  "
                  className="img-fluid syncIcon"
                />
                Sync
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="cusomer_box">
              <div className="searchBox_">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value={searchVal}
                  onChange={(e) => {
                    setSearchVal(e.target.value);
                    getAllCustomersList(1, 10, sortById, e.target.value);
                  }}
                />

                <img
                  src={Images.Search}
                  alt="search"
                  className="img-fluid searchIcon"
                />
              </div>
              <div className="dropdown">
                {/* <button
                  className="filter_box dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={Images.MobileFilter}
                    alt="search"
                    className="img-fluid "
                  />
                </button> */}
                <div className="dropdown">
                  <select
                    className="filter_box"
                    onChange={(e) => handleChangeSort(e.target.value)}
                  >
                    <option value=""> All</option>
                    {scheduleTypeData.map((data, index) => (
                      <option
                        key={index}
                        value={data.id}
                        className="filter_items"
                      >
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*---- End mobile Customer Searchbar ----*/}
      {customerList?.length > 0
        ? customerList?.map((data, index) => {
          return (
            <>
              <div className="activities_user CustomerMobileBox p-3">
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="d-flex align-items-center">
                    <figure>
                      <img
                        src={Images.User}
                        alt="LeftIcon"
                        className="userIcon"
                      />
                    </figure>
                    <article>
                      <span className="billingDate">
                        {data.customer_name}
                      </span>
                      <p className="BillPeriod">
                        <span className="weeklydot"></span>
                        {data?.billing_type_details?.name}
                      </p>
                    </article>
                  </div>
                  <p>
                    {data?.billingInvoiceDetails[0]?.status === "send" ? (
                      <p className="sentBox">
                        {data?.billingInvoiceDetails[0]?.status}
                      </p>
                    ) : (
                      ""
                    )}
                    {data?.billingInvoiceDetails[0]?.status ===
                      "pending" ? (
                      <p className="PendingBox">
                        {data?.billingInvoiceDetails[0]?.status}
                      </p>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <div className="d-flex ">
                  <article className="billBox">
                    <span className="billingDate">
                      {data?.billing_type_details?.name === "Manual" ? <td className="subheadingCustomer">

                        {Boolean(
                          data?.billingInvoiceDetails[0]?.send_date
                        )
                          ? moment(
                            data?.billingInvoiceDetails[0]?.send_date
                          ).format("D MMM, YYYY")
                          : ""}
                        {` -`}
                        {Boolean(data?.billingInvoiceDetails[0]?.due_date)
                          ? moment(
                            data?.billingInvoiceDetails[0]?.due_date
                          ).format("D MMM, YYYY")
                          : ""}
                      </td> :
                        <td className="subheadingCustomer">

                          {Boolean(
                            data?.billingInvoiceDetails[0]?.billing_start_date
                          )
                            ? moment(
                              data?.billingInvoiceDetails[0]?.billing_start_date
                            ).format("D MMM, YYYY")
                            : ""}
                          {` -`}
                          {Boolean(data?.billingInvoiceDetails[0]?.send_date)
                            ? moment(
                              data?.billingInvoiceDetails[0]?.send_date
                            ).format("D MMM, YYYY")
                            : ""}
                        </td>
                      }
                    </span>
                    <span className="BillPeriod">Current Billing Period</span>
                  </article>
                  <article className="billBox">
                    <span className="billingDate">
                      {data?.billing_type_details?.name === "Manual" ? <td className="subheadingCustomer">

                        {Boolean(
                          data?.billingInvoiceDetails[1]?.send_date
                        )
                          ? moment(
                            data?.billingInvoiceDetails[1]?.send_date
                          ).format("D MMM, YYYY")
                          : ""}
                        {` -`}
                        {Boolean(data?.billingInvoiceDetails[1]?.due_date)
                          ? moment(
                            data?.billingInvoiceDetails[1]?.due_date
                          ).format("D MMM, YYYY")
                          : ""}
                      </td> :
                        <td className="subheadingCustomer">

                          {Boolean(
                            data?.billingInvoiceDetails[1]?.billing_start_date
                          )
                            ? moment(
                              data?.billingInvoiceDetails[1]?.billing_start_date
                            ).format("D MMM, YYYY")
                            : ""}
                          {` -`}
                          {Boolean(data?.billingInvoiceDetails[1]?.send_date)
                            ? moment(
                              data?.billingInvoiceDetails[1]?.send_date
                            ).format("D MMM, YYYY")
                            : ""}
                        </td>
                      }
                    </span>
                    <span className="BillPeriod">
                      Previous Billing Period
                    </span>
                  </article>
                </div>
                <div className="text-center">
                  <Link
                    to={
                      data?.billing_type_details?.name === "Manual"
                        ? `/manualbill/${data.customer_id}`
                        : `/autobill/${data.customer_id}`
                    }
                  >
                    <button type="button" className="reviewBtn w-100">
                      Review
                    </button>
                  </Link>
                </div>
              </div>
            </>
          );
        })
        : " "}

      {customerList?.length > 0 ? (
        <div className="col-12 ">
          <div className="customPagination mt-4">
            <div className="pagination">
              <p className="pageList">
                {pagingDataLabel}
                {` of `}
                {totalCount}
              </p>
              <div>
                <label for="cars" className="pageList">
                  Show list of
                </label>

                <select
                  name="cars"
                  id="perPageId"
                  onChange={handleChangePages}
                  value={perPageSize}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>

                </select>
              </div>
              {customerList?.length > 0 ? (
                <>
                  <ReactPaginate
                    className="paginationBox"
                    breakLabel=""
                    // nextLabel=">"
                    nextLabel={<i class="fa fa-chevron-right pageIcon" aria-hidden="true" ></i>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={0}
                    pageCount={Math.ceil(totalCount / perPageSize)}
                    previousLabel={<i class="fa fa-chevron-left pageIcon" aria-hidden="true" ></i>}
                    // previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AccountDashboard;
