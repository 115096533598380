import { all, call, put, take, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import {
  onErrorStopLoad,
  setGetScheduleType,
  setallCustomerList,
  setBillingdetailsById,
  setBillingInvoice,
  setBillingInvoiceGetbycustomer,
  setUpdateBillingInvoice,
  setBillingInvoiceById,
  setCreateActivity,
  setActivityByInvoiceId,
  setManuallInvoice,
  setCreateCustomers,
  setGetUrl,
  setUpdateActivity,
  setDeleteActivity,
} from "../../slices/dashboard";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* getAllCustomerList(action) {
  let url = `${ApiPath.DashboardApiPath.GET_ALL_CUSTOMERLIST}?page=${action.payload.page}&limit=${action.payload.limit}`;

  if (Boolean(action.payload.search)) {
    url = `${url}&search=${action.payload.search}`;
  }
  if (Boolean(action.payload.billing_type)) {
    url = `${url}&billing_type=${action.payload.billing_type}`;
  }
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setallCustomerList(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setallCustomerList({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* getBillingDetailsbyId(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.DashboardApiPath.GET_BILLINGDETAILSBYID}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setBillingdetailsById(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setBillingdetailsById({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* billingInvoiceGetbyCustomer(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.DashboardApiPath.BILLING_INVOICE_GETBYCUSTOMER}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setBillingInvoiceGetbycustomer(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setBillingInvoiceGetbycustomer({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}
function* getBillingInvoiceById(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.DashboardApiPath.BILLING_INVOICE_GETBYID}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setBillingInvoiceById(resp.data.payload.Billinvoice));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {

    console.log(e,'error')

    yield put(setBillingInvoiceById({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* getScheduleType(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.DashboardApiPath.GET_SCHEDULE_TYPE),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetScheduleType(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetScheduleType({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* createBillingInvoice(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.DashboardApiPath.CREATE_BILLING_INVOICE),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setBillingInvoice(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setBillingInvoice({}));
    yield put(onErrorStopLoad());
    //toast.error(e.response.data.msg);
  }
}

function* updateBillingInvoice(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.DashboardApiPath.UPDATE_BILLING_INVOICE}/${action.payload.invoice_id}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUpdateBillingInvoice(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpdateBillingInvoice({}));
    yield put(onErrorStopLoad());
    //toast.error(e.response.data.msg);
  }
}
function* creteActivity(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.DashboardApiPath.CREATE_ACTIVITY),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setCreateActivity(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {

    console.log(e,'errorrrr')

    yield put(setCreateActivity({}));
    yield put(onErrorStopLoad());
    toast.error("Activites Cannot be empty");
  }
}
function* getActivityByInvoiceId(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.DashboardApiPath.GET_ACTIVITYBY_INVOICEID}/${action.payload.invoice_id}`)
    );
    if (resp.status) {
      yield put(setActivityByInvoiceId(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setActivityByInvoiceId({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* createManuallInvoice(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.DashboardApiPath.CREATE_MANUALINVOICE),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setManuallInvoice(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setManuallInvoice({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* CreateCustomersSync(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.DashboardApiPath.CREATE_CUSTOMER),
      (action.payload = action.payload)
    );
  
    if (resp.status) {
      toast.success(resp.data.msg)
      yield put(setCreateCustomers(resp.data));
      yield call(action.payload.cb, resp.data);
  
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
   toast.error(e.response.data.msg);
  }
}
function* GetUrl(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.UrlTokens.GETURL),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetUrl(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetUrl({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}
function* GetToken(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.UrlTokens.GETTOKEN),
      (action.params = action.payload)
    );
    if (resp.status) {
      yield put(setGetUrl(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetUrl({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}
function* updateActivity(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.DashboardApiPath.UPDATE_ACTIVITY}/${action.payload.id}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUpdateActivity(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpdateActivity({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}
function* deleteActivity(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.DashboardApiPath.DELETE_ACTIVITY}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setDeleteActivity(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetUrl({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* userDashboard() {
  yield all([
    takeLatest("dashboard/allCustomerList", getAllCustomerList),
    takeLatest("dashboard/getScheduleType", getScheduleType),
    takeLatest("dashboard/getBillingDetailsbyId", getBillingDetailsbyId),
    takeLatest("dashboard/createBillingInvoice", createBillingInvoice),
    takeLatest(
      "dashboard/billingInvoiceGetbyCustomer",
      billingInvoiceGetbyCustomer
    ),
    takeLatest("dashboard/updateBillingInvoice", updateBillingInvoice),
    takeLatest("dashboard/getBillingInvoiceById", getBillingInvoiceById),
    takeLatest("dashboard/creteActivity", creteActivity),
    takeLatest("dashboard/getActivityByInvoiceId", getActivityByInvoiceId),
    takeLatest("dashboard/createManuallInvoice", createManuallInvoice),
    takeLatest("dashboard/createCustomers", CreateCustomersSync),
    takeLatest("dashboard/updateActivity", updateActivity),
    takeLatest("dashboard/deleteActivity", deleteActivity),
    takeLatest("dashboard/GetUrl", GetUrl),
    takeLatest("dashboard/GetToken", GetToken),
  ]);
}

export default userDashboard;
