import React, { useState } from 'react';
import * as Images from "../../../utilities/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { userOtp } from '../../../redux/slices/auth';
import { resendOtp } from '../../../redux/slices/auth';

//import { useAuthSelector } from '../../../redux/selector/auth';


const Enterotp = () => {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    //const authSelector = useAuthSelector()
    // let email = location?.state?.email;

let  email  = localStorage.getItem("email")

    const handleChangeOtp = (value) => {
        setOtp(value);
    }

    const resendOTP = (e) => {
        e.preventDefault();
        let params = {
            email: email,
        }
        dispatch(resendOtp({
            ...params, cb(res) {
                if (res) {
                    toast.success("OTP Resent Successfully")
                    navigate('/enterotp');
                }
                else {
                    navigate("/")
                }
            }
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!otp) {
            toast.error("Please enter OTP");
            return;
        }
        let params = {
            email: email,
            otp: parseInt(otp),
        }
        dispatch(userOtp({
            ...params, cb(res) {
                if (res.status) {
                    toast.success("OTP verified")
                    navigate("/recoverpassword")
                }
                else {
                    //toast.error("invalid otp")
                }
            }
        }))

    }




    return (
        <>
            <div className='login'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="logo_icon">
                            <img src={Images.logo} alt='Location' className='logo_img' />
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <img src={Images.LoginLeft} alt='LeftIcon' className="LoginLeft" />
                        <p className="copyright mainCopyright">© Copyright 2023 all rights reserved.</p>
                    </div>
                    <div className="col-lg-5">
                        <div className="login_details">
                            <h1 className="login_ mb-2">Enter OTP</h1>
                            <p className="welcome_note mb-4">Enter the OTP that we just sent your on your
                                email address.</p>
                            <form onSubmit={(e) => handleSubmit(e)} id="form">
                                <div className='otp_box'>
                                    <OtpInput
                                    containerStyle={{justifyContent:'space-evenly'}}
                                        value={otp}
                                        onChange={handleChangeOtp}
                                        numInputs={4}
                                        renderInput={(props) => <input {...props} className='enterOtp' />}
                                    />
                                </div>
                                <p className='resend_otp mt-5 text-center'>I didn't received code! <span className='resend' onClick={(e) => { resendOTP(e) }}>Please Resend</span> </p>
                                <button class="submit_btn w-100 my-3" type="submit" onClick={(e) => { handleSubmit(e) }}>
                                    Submit
                                </button>
                                <p class="forget_password text-center"><Link to='/'>
                                    <img src={Images.backIcon} alt='LeftIcon' className="backIcon" />Go  Back</Link></p>
                            </form>
                        </div>
                        <p className="copyright mobileCopyright text-center">© Copyright 2023 all rights reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Enterotp