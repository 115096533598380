import { useSelector } from "react-redux";

export const useAuthSelector = () => {
  return useSelector((state) => state.auth)
}

export const useUserSelector = () => {
  return useSelector((state) => state.user)
}

export const useDashboardhSelector = () => {
  return useSelector((state) => state.dashboard)
}

