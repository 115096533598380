const ApiPath = {
    GET_USER: '/api/v1/users/',
    PUT_USER: '/api/v1/users',
    CREATE_USER: '/api/users',
    PATCH_USER: '/api/users/2',
    DELETE_USER: '/api/users/2',
    PROFILEPIC_USER:'/api/v1/users/profile/upload',

    AuthApiPath: {
        LOGIN_USER: '/api/v1/users/login',
        SIGNUP_USER: '/api/v1/users/create',
        FORGOT_PASSOWORD:'/api/v1/users/forgot-password/',
        OTP_USER: '/api/v1/users/verify-otp',
        RESEND_OTP:'/api/v1/users/resend-otp',
        RECOVR_PASSWORD:'/api/v1/users/reset-password',
        CHANGE_PASSWORD:'/api/v1/users/change-password',
        LOGOUT_USER: '/api/v1/users/logout',
    },

    UrlTokens:{
        GETURL:'/api/v1/users/authuri',
        GETTOKEN:'/api/v1/users/gettoken',
    },

    DashboardApiPath: {
        GET_ALL_CUSTOMERLIST: '/api/v1/billing_details',
        GET_BILLINGDETAILSBYID:'/api/v1/billing_details',
        GET_ALL_DASHBOARD_DATA: '/api/v1/dashboard/index',
        GET_SCHEDULE_TYPE: '/api/v1/billing_schedule_types',
        CREATE_BILLING_INVOICE:'/api/v1/billing_invoices/create',
        BILLING_INVOICE_GETBYCUSTOMER:'/api/v1/billing_invoices/getByCustomer',
        UPDATE_BILLING_INVOICE:'/api/v1/billing_invoices/',
        BILLING_INVOICE_GETBYID:'/api/v1/billing_invoices/',
        CREATE_ACTIVITY:'/api/v1/activities/',
        GET_ACTIVITYBY_INVOICEID:'/api/v1/activities/getByInvoice/',
        CREATE_CUSTOMER:'/api/v1/billing_details/create',
        CREATE_MANUALINVOICE:'/api/v1/invoices/create',
        UPDATE_ACTIVITY:'api/v1/activities/',
        DELETE_ACTIVITY:'/api/v1/activities/',
    }, 
}

export default ApiPath;
