import React from "react";
import * as Images from "../../../utilities/images";
import { useAuthSelector } from "../../../redux/selector/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/slices/auth";

const MainNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authSelector = useAuthSelector();
  const userName = authSelector?.userInfo?.firstName;
  const profilePhoto = authSelector?.userInfo?.profilePhoto;
  // const hasRememberMeChecked = localStorage.getItem("hasRememberMeChecked");

  const userLogout = () => {
    dispatch(
      logout({
        cb(res) {
          if (res.status) {
            navigate("/");
          } else {
          }
        },
      })
    );
  };

  return (
    <>
      <div className="navMain">
        <div className="container">
          <div className="nav_box">
            <div className="main_logo">
              {/* <Link to="/dashboard">
               
              </Link> */}
              <img src={Images.logo} alt="" onClick={()=>window.location.href="/dashboard" }className="logo" /> 
            </div>
            <div className="user_details">
              <img src={Images.Welcome} alt="" className="welcomeIcon" />
              <span className="userName">Hey, Welcome {userName}</span>
              <div class="dropdown">
                <span
                  class="dropdown-toggle userDetails"
                  type="button"
                  id="userDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {userName}
                </span>
                <ul
                  class="dropdown-menu userMenu"
                  aria-labelledby="userDropdown"
                >
                  <li className="user_menudetails">
                    <Link class="user-item" to="/viewedit">
                      <img
                        src={Images.EditIcon}
                        alt="edit"
                        className="dropdownIcon"
                      />{" "}
                      View & Edit Profile
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      userLogout();
                    }}
                    className="user_menudetails"
                  >
                    <a class="user-item" href="#">
                      <img
                        src={Images.Logout}
                        alt="edit"
                        className="dropdownIcon"
                      />
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
              <img src={profilePhoto} alt="" className="userIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className="navMain mobileMain">
        <div className="container">
          <div className="nav_box">
            <div className="main_logo">
              <Link to="/dashboard">
                <img src={Images.logo} alt="" className="logo" />
              </Link>
              <div className="">
                <img src={Images.Welcome} alt="" className="welcomeIcon" />
                <span className="userName">Hey, Welcome {userName}</span>
              </div>
            </div>
            <div className="user_details">
              <div class="dropdown">
                <span
                  class="dropdown-toggle userDetails"
                  type="button"
                  id="userDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {userName}
                </span>
                <ul
                  class="dropdown-menu userMenu"
                  aria-labelledby="userDropdown"
                >
                  <li className="user_menudetails">
                    <Link class="user-item" to="/viewedit">
                      <img
                        src={Images.EditIcon}
                        alt="edit"
                        className="dropdownIcon"
                      />{" "}
                      View & Edit Profile
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      userLogout();
                    }}
                    className="user_menudetails"
                  >
                    <a class="user-item" href="#">
                      <img
                        src={Images.Logout}
                        alt="edit"
                        className="dropdownIcon"
                      />
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
              <img src={profilePhoto} alt="" className="userIcon" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainNav;
