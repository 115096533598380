import React, { useState, useEffect } from "react";
//import { useLocation } from "react-router-dom";
import { GetToken, setGetToken } from "../../redux/slices/dashboard";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const RedirectPage = () => {
  const dispatch = useDispatch();
console.log(window.location.href, "window.location.href");
  useEffect(() => {
    getRefreshToken(window.location.href);
  }, []);

  const getRefreshToken = (getUrl) => {
    let params = {
      url: getUrl,
    };
    dispatch(
      GetToken({
        ...params,
        cb(res) {
          if (res?.data) {
            setGetToken(res?.data?.payload);
            window.location = "/dashboard";
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  return (
    <div>
      <h1>Redirect-Url-Page</h1>
    </div>
  );
};
export default RedirectPage;
