import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import {
  onErrorStopLoad,
  setUserLogin,
  setUserOtp,
  setResendOtp,
  setrecoverPass,
  setforgotPass,
  setPutUserInfo,
  setpostUserProfilePic,
  setUserInfo,
  setLogout,
  setChangePassword,
} from "../../slices/auth";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* userLogin(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.LOGIN_USER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      
       const isRememberMe = localStorage.getItem("hasRememberMeChecked");
      // if (isRememberMe?.toLowerCase?.() === 'true') {
        localStorage.setItem(
          "authToken",
          resp.data.payload.token ? resp.data.payload.token : ""
        );
      // } else {
      //   sessionStorage.setItem(
      //     "authToken",
      //     resp.data.payload.token ? resp.data.payload.token : ""
      //   );
      // }
     

      yield put(setUserLogin(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUserLogin({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* userOtp(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.OTP_USER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      const isRememberMe = localStorage.getItem("hasRememberMeChecked");
      if (isRememberMe?.toLowerCase?.() === 'true') {
        localStorage.setItem(
          "authToken",
          resp.data.payload.token ? resp.data.payload.token : ""
        );
      } else {
        sessionStorage.setItem(
          "authToken",
          resp.data.payload.token ? resp.data.payload.token : ""
        );
      }

      yield put(setUserOtp(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUserOtp({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* forgotPass(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.FORGOT_PASSOWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setforgotPass(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setforgotPass({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* resendOtp(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.RESEND_OTP),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setResendOtp(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setResendOtp({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* RecoverPass(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.RECOVR_PASSWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setrecoverPass(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setrecoverPass({}));
    yield put(onErrorStopLoad());
    //toast.error(e.response.data.msg);
  }
}

function* changePassword(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.CHANGE_PASSWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setChangePassword(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setChangePassword({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* userLogout(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.AuthApiPath.LOGOUT_USER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      localStorage.removeItem("authToken");
      localStorage.removeItem("persist:root");
      sessionStorage.removeItem("authToken");
      //toast.success(action.res.data.msg);
      yield put(setLogout());
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(e.response.data.msg);
  }
}
function* fetchUser(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.GET_USER}${action.payload.user_id}`)
    );
    yield put(setUserInfo(resp.data.payload));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setUserInfo({}));
  }
}

function* updateUser(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = ApiPath.PUT_USER),
      (action.payload = action.payload)
    );
    yield put(setPutUserInfo(resp.data.payload));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setPutUserInfo({}));
    //toast.error(e.response.data.msg);
  }
}

function* ProfilePicUpload(action) {
  try {
    const resp = yield call(
      ApiClient.postFormData,
      (action.url = ApiPath.PROFILEPIC_USER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setpostUserProfilePic(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setpostUserProfilePic({}));
    //toast.error(e.response.data.msg);
  }
}

function* authSaga() {
  yield all([
    takeLatest("auth/userLogin", userLogin),
    takeLatest("auth/userOtp", userOtp),
    takeLatest("auth/resendOtp", resendOtp),
    takeLatest("auth/forgotPass", forgotPass),
    takeLatest("auth/recoverPass", RecoverPass),
    takeLatest("auth/changePassword", changePassword),
    takeLatest("auth/logout", userLogout),
    takeLatest("user/getUserInfo", fetchUser),
    takeLatest("user/putUserInfo", updateUser),
    takeLatest("user/postUserProfilePic", ProfilePicUpload),
  ]);
}

export default authSaga;
