import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import { Link } from "react-router-dom";
import CustomModal from "../../containers/AllModal/CustomModal";
import ChangePassword from "./changePassword.js";
import { putUserInfo } from "../../../redux/slices/user";
import { getUserInfo } from "../../../redux/slices/user";
import { postUserProfilePic } from "../../../redux/slices/user";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Viewedit = () => {
  const [getData, setgetData] = useState([]);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [profilePic, setprofilePic] = useState();
  const [isFormUpserted, setIsFormUpserted] = useState(false);
  const navigate = useNavigate();

  //closeModal

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    let params = {
      user_id: 1,
    };
    dispatch(
      getUserInfo({
        ...params,
        cb(res) {
          if (res) {
            setgetData(res?.data?.payload);
            setFirstName(res.data.payload.firstName);
            setLastName(res.data.payload.lastName);
            setprofilePic(res?.data?.payload?.profilePhoto);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  //EndcloseModal

  const onUploadPicture = (e) => {
    if (e.target.files?.length <= 0) return;

    const isImageValid = /\.(gif|jpeg|tiff?|png|jpg|PNG|JPEG)$/i.test(
      e.target.files[0].name
    );
    if (isImageValid === false) {
      toast.error("Please select valid image format");
      return;
    }

    setprofilePic(e.target.files[0]);
    setIsFormUpserted(true);

    let params = {
      profile_image: e.target.files[0],
    };

    dispatch(
      postUserProfilePic({
        ...params,
        cb(res) {
          if (res.data.payload) {
            setprofilePic(res?.data?.payload?.profile_photo);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const profileEdit = (e) => {
    e.preventDefault();
    if (firstName === "") {
      toast.error("Please Enter First Name");
      return;
    } else if (lastName === "") {
      toast.error("Please Enter Last Name");
      return;
    } else if (firstName.length < 3) {
      toast.error("Max limit should be 3");
      return;
    }

    const isFirstNameValid =
      /^[a-z][a-z]*$/i.test(firstName.trim()) ||
      /[a-zA-Z]+( [a-zA-Z]+)$/.test(firstName.trim());
    const isLastNameValid =
      /^[a-z][a-z]*$/i.test(lastName.trim()) ||
      /[a-zA-Z]+( [a-zA-Z]+)$/.test(lastName.trim());

    const isImage = /\.(gif|jpeg|tiff?|png|jpg)$/i.test(profilePic);

    if (!isFirstNameValid) {
      toast.error("Invalid First Name");
      return;
    }

    if (!isLastNameValid) {
      toast.error("Invalid Last Name");
      return;
    }
    if (!isImage) {
      toast.error("Please select valid image format");
      return;
    }

    let params = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      profile_photo: profilePic,
    };
    dispatch(
      putUserInfo({
        ...params,
        cb(res) {
          if (res.status) {
            setprofilePic(res?.data?.payload?.profilePhoto);
            navigate("/dashboard");
            //toast.success("Admin Profile Updated Succesfully");
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const resetData = () => {
    getUserData();
  };

  return (
    <>
      <div className="backbutton mt-3">
        <div className="button_main">
          <Link to={"/dashboard"} className="back_btn">
            <img
              src={Images.BackButton}
              alt="backbutton"
              className="backBtnIcon"
            />
            Go Back
          </Link>
          <h5 className="pageHeading d-block">My Profile</h5>
        </div>
        {isFormUpserted && (
          <div className="button_box">
            <button
              type="button"
              onClick={(e) => resetData(e)}
              className="Discard_btn"
            >
              Discard
            </button>
            <button
              type="button"
              onClick={(e) => profileEdit(e)}
              className="save_btn"
            >
              Save
            </button>
          </div>
        )}
      </div>
      <div className="editprofile">
        <div className="profileBox">
          <div className="row">
            <div className="col-lg-2">
              <div class="edit_profile">
                <label for="file-upload" class="custom-file-upload">
                  <img
                    id="profileImagePreview"
                    src={profilePic}
                    class="img-fluid profile_modal"
                  />
                  <img
                    src={Images.changeProfile}
                    alt="edit_icon"
                    class="img-fluid edit_iconmodal"
                  />
                </label>
                <input
                  id="file-upload"
                  type="file"
                  onChange={onUploadPicture}
                  name="profile_image"
                  className="d-none"
                />
              </div>
            </div>
            {/* <form onSubmit={(e) => profileEdit(e)}> */}
            <div className="col-lg-4">
              <div class="form-group mb-3">
                <label class="input_label" for="Fname">
                  First Name
                </label>
                <input
                  type="text"
                  name="fname"
                  minLength={3}
                  maxLength={25}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setIsFormUpserted(true);
                  }}
                  className="form-control input_box "
                />
                <img
                  src={Images.Profile}
                  alt="LeftIcon"
                  className="inputIcon"
                />
                <span className="toggle_password_ info_icon"></span>
              </div>
              <div class="form-group mb-3">
                <label class="input_label" for="email">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control input_box "
                  value={getData.email}
                  disabled
                />
                <img
                  src={Images.Message}
                  alt="LeftIcon"
                  className="inputIcon"
                />
                <span className="toggle_password_ info_icon"></span>
              </div>
            </div>
            <div className="col-lg-4">
              <div class="form-group mb-3">
                <label class="input_label" for="Lname">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lname"
                  minLength={3}
                  maxLength={25}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setIsFormUpserted(true);
                  }}
                  className="form-control input_box "
                />
                <img
                  src={Images.Profile}
                  alt="LeftIcon"
                  className="inputIcon"
                />
                <span className="toggle_password_ info_icon"></span>
              </div>
            </div>
            {/* </form> */}
            <div className="col-lg-2">
              <p className="text-end">
                <img src={Images.changePassword} alt="LeftIcon" className="" />
                <Link
                  className="changePassword"
                  onClick={() => {
                    setModalDetail({ show: true, flag: "ChangePassword" });
                    setKey(Math.random());
                  }}
                >
                  Change Password
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*---- Start mobile responsive ----*/}
      <div className="editprofile editprofileMobile   ">
        <div className="profileBox">
          <div className="ChnageProfileBox">
            <div class="edit_profile">
              <label for="file-upload" class="custom-file-upload">
                <img
                  id="profileImagePreview"
                  src={profilePic}
                  value={profilePic}
                  alt="profile_icon"
                  class="img-fluid profile_modal"
                />
                <img
                  src={Images.changeProfile}
                  alt="edit_icon"
                  class="img-fluid edit_iconmodal"
                />
              </label>
              <input
                id="file-upload"
                type="file"
                name="profile_image"
                className="d-none"
              />
            </div>
            <div className="text-end">
              <img src={Images.changePassword} alt="LeftIcon" className="" />
              <Link
                className="changePassword"
                onClick={() => {
                  setModalDetail({ show: true, flag: "ChangePassword" });
                  setKey(Math.random());
                }}
              >
                Change Password
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div class="form-group mb-3">
                <label class="input_label" for="Fname">
                  First Name
                </label>
                <input
                  type="text"
                  name="fname"
                  minLength={3}
                  maxLength={25}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setIsFormUpserted(true);
                  }}
                  className="form-control input_box "
                />
                <img
                  src={Images.Profile}
                  alt="LeftIcon"
                  className="inputIcon"
                />
                <span className="toggle_password_ info_icon"></span>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div class="form-group mb-3">
                <label class="input_label" for="email">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control input_box "
                  value={getData.email}
                  disabled
                />
                <img
                  src={Images.Message}
                  alt="LeftIcon"
                  className="inputIcon"
                />
                <span className="toggle_password_ info_icon"></span>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div class="form-group mb-3">
                <label class="input_label" for="Lname">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lname"
                  minLength={3}
                  maxLength={25}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setIsFormUpserted(true);
                  }}
                  className="form-control input_box "
                />
                <img
                  src={Images.Profile}
                  alt="LeftIcon"
                  className="inputIcon"
                />
                <span className="toggle_password_ info_icon"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button_box  Mobilebutton_box my-3">
        <button type="button" className="Discard_btn">
          Discard
        </button>
        <button
          type="button"
          onClick={(e) => profileEdit(e)}
          className="save_btn"
        >
          Save
        </button>
      </div>
      {/*---- End Mobile Responsive ----*/}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "ChangePassword"
            ? "commonWidth customContent"
            : ""
        }
        ids={modalDetail.flag === "ChangePassword" ? "ChangePassword" : ""}
        child={
          modalDetail.flag === "ChangePassword" ? (
            <ChangePassword
              id={modalDetail.id}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              {modalDetail.flag === "ChangePassword" ? (
                <h2 className="headingSmall_">Change Password</h2>
              ) : (
                ""
              )}
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Viewedit;
