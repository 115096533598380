import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import { useAuthSelector } from '../../redux/selector/auth';

const AuthLayout = () => {

  const authSelector = useAuthSelector();
  var token = localStorage.getItem('authToken')
  const isLoggedIn = authSelector.isLoggedIn

  return (
    <>
      <main className='logBox' id='logBox'>
        {token && isLoggedIn ?
          <Navigate to='/dashboard'></Navigate>
          :
          <Outlet />
        }
      </main>
    </>
  );
};

export default AuthLayout;