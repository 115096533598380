import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
// import ReactPaginate from 'react-paginate';

const Pagination = () => {
    return (
        <>
            Pagination
        </>
    )
}

export default Pagination