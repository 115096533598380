import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Images from "../../../utilities/images";
import { Link, useParams } from "react-router-dom";
import CustomModal from "../AllModal/CustomModal";
import BillingDetails from "./BillingDetails ";
import CreateBill from "./CreateBill";
import EditBillingDetails from "./EditBillingDetails";
import { useNavigate } from "react-router-dom";
import {
  billingInvoiceGetbyCustomer,
  creteActivity,

} from "../../../redux/slices/dashboard";
import { useDashboardhSelector } from "../../../redux/selector/auth";

const ManualBilling = () => {
  const dashboardSelector = useDashboardhSelector();
  const invoiceId = dashboardSelector?.createBillingInvoice?.id;
  const dispatch = useDispatch();
  const param = useParams();
  const customer_id = param?.id;
  const [billingdetails, setbillingDetails] = useState([]);
  const [isShowBillingModalActivites, setShowActivities] = useState(false);
  //onst [allActivity, setAllActivity] = useState([]);
  const [customerStatus, setCustomerStatus] = useState("");
  const navigate = useNavigate();
  const [key, setKey] = useState(Math.random());
  const [billingFormData, setBillingDetailData] = useState([]);
  const [hasActivityFormErrors, setHasActivityFormErrors] = useState(false);
  //const [showInvoiceButton, setShowInvoiceButton] = useState(false);

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setBillingDetailData([]);
    setHasActivityFormErrors(false);
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
    // fetchData();
  };
  //EndcloseModal

  useEffect(() => {
    getBillingDetails();
    //getAllActivity();
  }, []);

  const getBillingDetails = () => {
    let params = {
      id: customer_id,
    };

    dispatch(
      billingInvoiceGetbyCustomer({
        ...params,
        cb(res) {
          if (res?.data) {
            setbillingDetails(res?.data?.payload);
            //setcustomerId(res?.data?.payload?.customer_id);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  const handleSave = (e, type = 1) => {
    e.preventDefault();

    if (billingFormData?.length <= 0) {
      toast.error("No Activity added");
      return;
    }

    if (hasActivityFormErrors) {
      toast.error("Please correct form errors");
      return;
    }
    const activityInvoice = billingFormData?.map((dataOfActivity) => {
      return {
        service_contract: dataOfActivity?.servicecontract,
        start_date: moment(dataOfActivity?.startDate).format("YYYY-MM-DD"),
        end_date: moment(dataOfActivity?.endDate).format("YYYY-MM-DD"),
        quantity: dataOfActivity?.quantity,
        rate: dataOfActivity?.rate,
        discription: dataOfActivity?.description,
      };
    });

    let params = {
      billing_invoice_id: type == 1 ? invoiceId : modalDetail.id,
      activityDetails: activityInvoice,
    };

    dispatch(
      creteActivity({
        ...params,
        cb(res) {
          getBillingDetails();
          if (res) {
            handleOnCloseModal();
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const getManualActivitesData = (isStatusSend) => {
    return billingdetails?.length > 0
      ? billingdetails
        .filter((filterData) =>
          isStatusSend
            ? filterData?.data?.status?.toLowerCase() === "send"
            : filterData?.data?.status?.toLowerCase() === "pending"
        )
        ?.map((data, index) => {
          return (
            <>
              <div className="BoxOuter mt-2">
                <div className="BillingInvoice">
                  <h6 className="invoice_heading">
                    Invoice:{" "}
                    <span className="invoiceNumber">{data?.data?.id}</span>
                  </h6>
                  {/* <p className="sentBox">{data?.data?.status}</p> */}
                  <p>
                    {data?.data?.status === "send" ? (
                      <p className="sentBox">{data?.data?.status}</p>
                    ) : (
                      <p className="PendingBox">{data?.data?.status}</p>
                    )}
                  </p>
                </div>

                <div className="row mt-3 BillingBox">
                  <div className="col-lg-2">
                    <h4 className="billingDetails">Bill To</h4>
                    <h5 className="customerName">
                      {data?.data?.customer_name}
                    </h5>
                    <p className="customerAddress">
                      {data?.data?.bill_to?.City}
                    </p>
                    <p className="customerAddress">
                      {data?.data?.bill_to?.Line1}
                    </p>
                    <p className="customerAddress">
                      {data?.data?.bill_to?.PostalCode}
                    </p>
                    <p className="customerAddress">
                      {" "}
                      {data?.data?.bill_to?.CountrySubDivisionCode}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <h4 className="billingDetails">Ship To</h4>
                    <h5 className="customerName">
                      {data?.data?.customer_name}
                    </h5>
                    <p className="customerAddress">
                      {data?.data?.ship_to?.City}
                    </p>
                    <p className="customerAddress">
                      {data?.data?.ship_to?.Line1}
                    </p>
                    <p className="customerAddress">
                      {data?.data?.ship_to?.PostalCode}
                    </p>
                    <p className="customerAddress">
                      {data?.data?.ship_to?.CountrySubDivisionCode}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <h4 className="billingDetails">Billing Type</h4>
                    <h5 className="customerName">
                      {data?.data?.billing_type_details?.name}
                    </h5>
                  </div>
                  <div className="col-lg-2">
                    <h4 className="billingDetails">Sent Date</h4>
                    <h5 className="customerName">
                      {moment(data?.data?.send_date).utc().format("YYYY-MM-DD")}
                    </h5>
                  </div>
                  <div className="col-lg-2">
                    <h4 className="billingDetails">Due Date</h4>
                    <h5 className="customerName">
                      {moment(data?.data?.due_date).utc().format("YYYY-MM-DD")}{" "}
                    </h5>
                  </div>
                  <div className="col-lg-2 text-end">
                    <h4 className="billingDetails text-end">Total Amount</h4>
                    <p className="totalBill">${data?.total_amt}</p>
                    <button
                      className="Discard_btn text-end"
                      type="button"
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "BillingDetails",
                          id: data?.data?.id,
                        });
                        setKey(Math.random());
                      }}
                    >
                      View Details
                    </button>
                  </div>
                </div>
                <div className="table-responsive mt-3">
                  <table id="ManualBilling" className="automatic_Billing">
                    <thead>
                      <tr className="billingBox">
                        <th className="headingCustomer">Activity </th>
                        <th className="headingCustomer">Qty</th>
                        <th className="headingCustomer">Rate</th>
                        <th className="headingCustomer">Amount</th>
                      </tr>
                    </thead>
                    {data?.data?.activity_details?.map((data, index) => {
                      return (
                        <tbody>
                          <tr className="activities_user ">
                            <td className="subheadingCustomer">
                              <img
                                src={Images.ActivityIcon}
                                alt="LeftIcon"
                                className="bikkdetailsIcon"
                              />
                              <div className="">
                                <p>
                                  <img
                                    src={Images.Calendar}
                                    alt="LeftIcon"
                                    className=""
                                  />
                                  <span className="billingDate">
                                    {moment(data?.start_date).format(
                                      "D MMM, YYYY"
                                    )}{" "}
                                    -{" "}
                                    {moment(data?.end_date).format(
                                      "D MMM, YYYY"
                                    )}
                                  </span>
                                </p>
                                <h6 className="billingHeading">
                                  {data?.service_contract}
                                </h6>
                                <p className="billingSubheading">
                                  {data?.discription}
                                </p>
                              </div>
                            </td>
                            <td className="subheadingCustomer">
                              {data?.quantity}
                            </td>
                            <td className="subheadingCustomer">
                              {data?.rate}
                            </td>
                            <td className="subheadingCustomer">
                              {" "}
                              {data?.total_rate}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>

                <div className="TotalBillDetail">
                  <div className="SubTotal">
                    <span className="SubTotalText">Subtotal</span>
                    <span className="SubTotalText">${data?.sub_total}</span>
                  </div>
                  <div className="SubTotal">
                    <span className="SubTotalText">
                      HST (ON) @{data?.tax}%
                    </span>
                    <span className="SubTotalText">${data?.total_tax}</span>
                  </div>
                </div>
              </div>
            </>
          );
        })
      : isStatusSend ? "No Records Found!" : "";
  };

  const isPrevBillingData =
    billingdetails?.length > 0
      ? billingdetails.some(
        (filterData) => filterData?.data?.status?.toLowerCase() === "send"
      )
      : false;

  return (
    <>
      <section className="manualBilling">
        <div className="CustomersSearch my-3">
          <div className="row w-100">
            <div className="col-lg-6">
              <div className="button_main">
                <Link to={"/dashboard"} className="back_btn">
                  <img
                    src={Images.BackButton}
                    alt="backbutton"
                    className="backBtnIcon"
                  />
                  Go Back
                </Link>
                {/* <h3 className="pageHeading d-block">Billing Details </h3> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cusomer_box">
                {billingdetails?.length > 0 && (
                  <>
                    <h4 className="filterHeading text-end">Invoice push:</h4>
                    <button className="filter_box " type="button">
                      Manual
                    </button>
                  </>
                )}
              
                  <Link
                    className="setupBillingBtn"
                    to={`/setupbill/${customer_id}/manual`}
                  >
                      <button className="sync_btn">
                    <img
                      src={Images.Setting}
                      alt="setting"
                      className="img-fluid syncIcon"
                    />
                    Setup Billing Details
                    </button>
                  </Link>
            
              </div>
            </div>
          </div>
        </div>
        <div className="createBill">
          <button
            className="newBill text-center"
            type="button"
            onClick={() => {
              setBillingDetailData([]);
              setHasActivityFormErrors(false);
              setModalDetail({
                show: true,
                flag: "CreateBill",
                customerId: customer_id,
              });
               setShowActivities(false)
              setKey(Math.random());
            }}
          >
            <img
              src={Images.AddIcon}
              alt="addicon"
              className="img-fluid addIcon"
            />
            Create New Billing
          </button>
        </div>
        {getManualActivitesData(false)}
        {isPrevBillingData && (
          <div className="PreviousBill mt-4 mb-3">
            <h5 className="PreviousBillHeading">Previous Billings</h5>
          </div>
        )}
        {getManualActivitesData(true)}
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={
            modalDetail.flag === "BillingDetails"
              ? "commonWidth customContent"
              : ""
          }
          ids={
            modalDetail.flag === "BillingDetails"
              ? "BillingDetails"
              : modalDetail.flag === "CreateBill"
                ? "CreateBill"
                : modalDetail.flag === "EditBillingDetails"
                  ? "EditBillingDetails"
                  : ""
          }
          child={
            modalDetail.flag === "BillingDetails" ? (
              <BillingDetails
                isManualBilling={true}
                customerId={customer_id}
                id={modalDetail.id}
                refreshPage={() => getBillingDetails()}
                close={() => handleOnCloseModal()}
                setCustomerStatusFun={(status) => setCustomerStatus(status)}

              />
            ) : modalDetail.flag === "CreateBill" ? (
              <CreateBill
                customerId={modalDetail.customerId}
                close={() => handleOnCloseModal()}
                refreshdata={() => getBillingDetails()}
                ids={
                  modalDetail.flag === "BillingDetails" ? "BillingDetails" : ""
                }
                updateFormData={(invoiceActivityData) => {
                  setBillingDetailData(invoiceActivityData);
                }}
                setShowModalActivities={(isShowActivities) => {
                  setShowActivities(isShowActivities);
                }}
                updateFormErrors={(activityFormErrors) => {
                  setHasActivityFormErrors(activityFormErrors.length > 0);
                }}
              />
            ) : modalDetail.flag === "EditBillingDetails" ? (
              <EditBillingDetails
                id={modalDetail.id}
                close={() => handleOnCloseModal()}
                refreshActivity={() => {
                  getBillingDetails();
                }}
                ids={
                  modalDetail.flag === "BillingDetails" ? "BillingDetails" : ""

                }
                updateFormData={(billingDetailData) => {
                  setBillingDetailData(billingDetailData);
                }}
                setShowModalActivities={(isShowActivities) => {
                  setShowActivities(isShowActivities);
                }}
                updateFormErrors={(activityFormErrors) => {
                  setHasActivityFormErrors(activityFormErrors.length > 0);
                }}
              />
            ) : (
              ""
            )
          }
          header={
            <div className="BillingModalHeader_">
              <div className="common_">
                <>
                  {modalDetail.flag === "BillingDetails" ? (
                    <div className="BillingModalHeading">
                      {/* <h2 className="headingSmall_">Billing Details</h2> */}
                      {customerStatus == "send" ?
                        "" :
                        <button
                          className="Discard_btn"
                          onClick={() => {
                            setBillingDetailData([]);
                            setHasActivityFormErrors(false);
                            setModalDetail({
                              show: true,
                              flag: "EditBillingDetails",
                              id: modalDetail.id,
                            });
                            setKey(Math.random());
                          }}
                        >
                          <img src={Images.FillEdit} />
                          Edit
                        </button>
                      }
                    </div>
                  ) : modalDetail.flag === "CreateBill" ? (
                    <div className="BillingModalHeading">
                      <h2 className="headingSmall_">Billing Details</h2>
                      {isShowBillingModalActivites && (
                        <button
                          className="save_btn"
                          onClick={(e) => handleSave(e, 1)}
                        >
                          Save Activity
                        </button>
                      )}
                    </div>
                  ) : modalDetail.flag === "EditBillingDetails" ? (
                    <div className="BillingModalHeading">
                      <h2 className="headingSmall_">Billing Details</h2>
                      <button
                        className="save_btn"
                        onClick={(e) => handleSave(e, 2)}
                      >
                        Save Activity
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </section>
    </>
  );
};

export default ManualBilling;
