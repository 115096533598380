import { Navigate, Outlet } from "react-router-dom";
import MainNav from "../components/common/mainNav";
import { useAuthSelector } from "../../redux/selector/auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {  useParams } from "react-router-dom";
import { GetUrl} from "../../redux/slices/dashboard";

const MainLayout = () => {
  const authSelector = useAuthSelector();
  const [getUrl, setGeturl] = useState();
  const [getToken, setGettoken] = useState();
  const dispatch = useDispatch();
  const param = useParams();
  const isRememberMe = localStorage.getItem("hasRememberMeChecked");

 // const token = isRememberMe?.toLowerCase?.() === 'true' ? localStorage.getItem('authToken') : sessionStorage.getItem('authToken')
  const token = localStorage.getItem('authToken') 
  const isLoggedIn = authSelector.isLoggedIn

  const CheckIsTokenExpired = () => {
  const userData = authSelector?.userInfo;
  const currentDate = new Date();

  //   if (userData?.refreshtokencreated) {
  //     const refreshTokenCreatedDate = new Date(userData.refreshtokencreated);
  //     const priorDate = new Date(
  //       new Date().setDate(refreshTokenCreatedDate.getDate() + 95)
  //     );
  //     if (priorDate < currentDate) {
  //       // eslint-disable-next-line no-restricted-globals
  //       // let confirmValue = confirm(" Calllll");
  //         swal({
  //           className: "sweetAlertDelete",
  //           title: "Session expired! Please Refresh",
  //           icon: "warning",
  //           dangerMode: true,
  //           buttons: true,
  //         }).then((isUrl) => {
  //           if (isUrl) {
  //             dispatch(
  //               GetUrl({
  //                 cb(res) {
  //                   if (res?.data) {
  //                     console.log(res.data.payload, "url get");
  //                     setGeturl(res?.data?.payload);
  //                   } else {
  //                     toast.error("something went wrong");
  //                   }
  //                 },
  //               })
  //             );
  //            // swal("Deleted!", "Your document has been deleted!", "success");
  //           }
  //         });

  //         const url = getUrl;
  //         // window.open(url);
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("target", "_blank");

  //         // Append to html link element page
  //         document.body.appendChild(link);

  //         // Start download
  //         link.click();

  //         // Clean up and remove the link
  //         link.parentNode.removeChild(link);
        
  //     }
  //   }
  };
  return (
    <>
      {CheckIsTokenExpired()}
      <MainNav />
      <main className="main">
        <div className="container">
          {token  && isLoggedIn ?
            <Outlet />
          :
            <Navigate to='/'></Navigate>
          }

        </div>
      </main>
    </>
  );
};

export default MainLayout;
