import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getBillingDetailsbyId,
  createBillingInvoice,
} from "../../../redux/slices/dashboard";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
//import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDashboardhSelector } from "../../../redux/selector/auth";

const CreateBill = (props) => {
  const dashboardSelector = useDashboardhSelector();
  const invoiceId = dashboardSelector?.createBillingInvoice?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [billingDetails, setbillingDetails] = useState([]);
  const [sentDate, setSentDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const param = useParams();
  const [customerId, setcustomerId] = useState(props.customerId || null);
  const [showActivities, setShowActivities] = useState(false);
  const [showInvoiceButton, setShowInvoiceButton] = useState(false);
  const [activityErrors, setActivityErrors] = useState([]);
  const [hideSetupInvoiceButton, setHideInvoiceButton] = useState(true);
  console.log(hideSetupInvoiceButton, "hideSetupInvoiceButton");
  const [invoiceActivity, setInvoiceActivity] = useState([
    {
      id: Math.random(),
      //billing_invoice_id:null,
      startDate: new Date(),
      endDate: new Date(),
      servicecontract: "",
      quantity: null,
      rate: null,
      description: "",
    },
  ]);

  const getBillingDetails = () => {
    let params = {
      id: props.customerId,
    };
    dispatch(
      getBillingDetailsbyId({
        ...params,
        cb(res) {
          if (res?.data) {
            setbillingDetails(res?.data?.payload);
            //setcustomerId(res?.data?.payload?.customer_id);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    getBillingDetails();
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    if (moment(sentDate).format('YYYY-MM-DD') > moment(dueDate).format('YYYY-MM-DD')) {
      toast.error(
        " Please select valid dates - Due Date should be grater then sent date"
      );
      return;
    } else {
      let params = {
        customer_id: customerId,
        billing_type: "4",
        billing_send_date: moment(sentDate).format('YYYY-MM-DD'),
        billing_due_date: moment(dueDate).format('YYYY-MM-DD'),
      };
      dispatch(
        createBillingInvoice({
          ...params,
          cb(res) {
            if (res.status) {
              setShowActivities(true);
              setHideInvoiceButton(false);

              props.refreshdata()
              props?.setShowModalActivities(true);
              navigate(`/manualbill/${customerId}`);
            } else {
              //navigate(`/autobill/${customerId}`);
            }
          },
        })
      );
    }
  };
  const initialValue = 0;
  const sumWithInitial = invoiceActivity.reduce(
    (accumulator, currentValue) =>
      accumulator + Number(currentValue.rate) * Number(currentValue.quantity),
    initialValue
  );

  const updateActivityData = (activityObj) => {
    const index = invoiceActivity.findIndex((x) => x.id === activityObj.id);
    setShowInvoiceButton(true);

    const invoiceActivityCopy = [...invoiceActivity];
    if (index >= 0) {
      invoiceActivityCopy[index] = activityObj;
      setInvoiceActivity(invoiceActivityCopy);
      props.updateFormData(invoiceActivityCopy);
    }
  };
  const errorActivityHandler = (activityObj, inputValue, inputProperty) => {
    const numbers = /^[0-9]+$/;
    const rateRegx = /^[1-9]\d*(\.\d+)?$/;

    const index = activityErrors.findIndex(
      (x) => x.id === activityObj.id && x.inputFieldName === inputProperty
    );

    const activityErrorsCopy = [...activityErrors];
    let isInvalidQty = false;
    let isInvalidRate = false;
    let isServiceContractorInValid = false;
    //let isDescriptionInValid = false;
    let starDateInValid = false;

    if (inputProperty === "startDate") {
      starDateInValid = activityObj?.endDate < inputValue;
    } else {
      isInvalidQty =
        (!inputValue.match(numbers) ||
          Number(inputValue) <= 0 ||
          Number(inputValue) > 999) &&
        inputProperty === "qty";

      isInvalidRate =
        (!inputValue.match(rateRegx) ||
          Number(inputValue) <= 0 ||
          Number(inputValue) > 9999) &&
        inputProperty === "rate";

      isServiceContractorInValid =
        (inputValue.length < 3 || inputValue.length > 100) &&
        inputProperty === "servicecontract";

      // isDescriptionInValid =
      //   Boolean(inputValue) && inputProperty === "description"
      //     ? inputValue.length < 3
      //     : false;
    }

    if (
      isInvalidQty ||
      isInvalidRate ||
      isServiceContractorInValid ||
      //isDescriptionInValid ||
      starDateInValid
    ) {
      if (index < 0) {
        const activityErrorObj = {
          id: activityObj.id,
          errorMessage: isServiceContractorInValid
            ? "Length should be between 3 to 100"
            // : isDescriptionInValid
            // ? "Length should be greater than 3"
            : starDateInValid
              ? "start Date must be less than end date"
              : "Please enter valid numeric number",
          inputFieldName: inputProperty,
        };
        activityErrorsCopy.push(activityErrorObj);
        setActivityErrors(activityErrorsCopy);
        props.updateFormErrors(activityErrorsCopy);
      }
    } else {
      if (index >= 0) {
        activityErrorsCopy.splice(index, 1);
        setActivityErrors(activityErrorsCopy);
        props.updateFormErrors(activityErrorsCopy);
      }
    }
  };

  const addMoreHandler = () => {
    const addedActivityInvoice = [
      ...invoiceActivity,
      {
        id: Math.random(),
        billing_invoice_id: null,
        startDate: new Date(),
        endDate: new Date(),
        servicecontract: "",
        quantity: null,
        rate: null,
        description: "",
      },
    ];
    setInvoiceActivity(addedActivityInvoice);
  };

  const handleCancel = (activityId) => {
    const index = invoiceActivity.findIndex((x) => x.id === activityId);
    const invoiceActivityCanceledCopy = [...invoiceActivity];

    if (index >= 0) {
      invoiceActivityCanceledCopy.splice(index, 1);
      setInvoiceActivity(invoiceActivityCanceledCopy);
      props.updateFormData(invoiceActivityCanceledCopy);
      const activityErrorsCopy = activityErrors.filter(
        (err) => err.id !== activityId
      );
      props.updateFormErrors(activityErrorsCopy);
    }
  };



  return (
    <>
      <div className="EditBillingDetails">
        <div class="BillingInvoice">
          {/* <div className='d-flex'>
                        <h6 class="invoice_heading">Invoice: <span class="invoiceNumber">{props.id}</span></h6>
                    </div> */}
          {/* <div className='text-end'>
                        <span class="invoiceNumber text-end m-0">{billingDetails?.customer_name}</span>
                        <h6 class="invoice_heading">{billingDetails?.bill_to?.City}  {billingDetails?.bill_to?.PostalCode}</h6>
                    </div> */}
        </div>
        <div className="row mt-3 BillingBox">
          <div className="col-lg-3">
            <h4 className="billingDetails">Bill To</h4>
            <h5 className="customerName">{billingDetails?.customer_name}</h5>
            <p className="customerAddress">{billingDetails?.bill_to?.City}</p>
            <p className="customerAddress">
              {billingDetails?.bill_to?.PostalCode}
            </p>
          </div>
          <div className="col-lg-3">
            <h4 className="billingDetails">Ship To</h4>
            <h5 className="customerName">{billingDetails?.customer_name}</h5>
            <p className="customerAddress">{billingDetails?.ship_to?.City}</p>
            <p className="customerAddress">
              {billingDetails?.ship_to?.PostalCode}
            </p>
          </div>
          <div className="col-lg-2">
            <h4 className="billingDetails">Billing Type</h4>
            {billingDetails?.billing_type_details?.name}
          </div>
          <div className="col-lg-2">
            <div class="form-group mb-3">
              <label class="input_label" for="uname">
                Sent Date{" "}
              </label>
              <DatePicker
                disabled={hideSetupInvoiceButton == false}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                selected={sentDate}
                onChange={(date) => {
                  setSentDate(date);
                }}
                dateFormat="MM/dd/yyyy"
                minDate={moment().toDate()}
                placeholderText="MM/DD/YYYY"
                className="form-control input_box "
              />
            </div>
          </div>
          <div className="col-lg-2">
            <div class="form-group mb-3">
              <label class="input_label" for="uname">
                Due Date{" "}
              </label>
              <DatePicker
                disabled={hideSetupInvoiceButton == false}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                selected={dueDate}
                onChange={(date) => {
                  setDueDate(date);
                }}
                dateFormat="MM/dd/yyyy"
                minDate={sentDate || moment().toDate()}
                placeholderText="MM/DD/YYYY"
                className="form-control input_box "
              />
            </div>
          </div>
        </div>
        <div className="button_box text-center ">
          {/* <button type="button" className="Discard_btn">
              Discard
            </button> */}
          {hideSetupInvoiceButton && (
            <button
              type="button"
              onClick={(e) => handleSave(e)}
              className="save_btn mt-3 "
            >
              Save Billing Invoice
            </button>
          )}
        </div>
        {showActivities && (
          <div>
            {invoiceActivity.map((activityData, index) => {
              return (
                <div key={activityData.id} className="ActivityMain mt-3">
                  <h6 className="activityHeading">Activity</h6>
                  <div className="text-end">
                    <img
                      src={Images.CanceIcon}
                      alt="LeftIcon"
                      className="CancelActivity"
                      onClick={() => handleCancel(activityData.id)}
                    />
                  </div>
                  <div className="ActivityBox">
                    <div className="row">
                      <div className="col-lg-4">
                        <div
                          class="form-group mb-3"
                          style={{
                            border: `${activityErrors.some(
                              (err) =>
                                err.id === activityData.id &&
                                err.inputFieldName === "servicecontract"
                            )
                                ? "solid 1px red"
                                : ""
                              }`,
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Enter Something..."
                            value={activityData.servicecontract}
                            className="form-control input_box "
                            onChange={(e) => {
                              errorActivityHandler(
                                activityData,
                                e.target.value,
                                "servicecontract"
                              );
                              updateActivityData({
                                id: activityData.id,
                                billinginvoiceId: invoiceId,
                                servicecontract: e.target.value,
                                startDate: activityData.startDate,
                                endDate: activityData.endDate,
                                rate: activityData.rate,
                                quantity: activityData.quantity,
                                description: activityData.description,
                              });
                            }}
                            required
                          />
                          <img
                            src={Images.ArrowDown}
                            alt="LeftIcon"
                            className="BillIcon"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div
                          class="form-group mb-3"
                          style={{
                            border: `${activityErrors.some(
                              (err) =>
                                err.id === activityData.id &&
                                err.inputFieldName === "startDate"
                            )
                                ? "solid 1px red"
                                : ""
                              }`,
                          }}
                        >
                          {/* <input type="Date" placeholder="1 March, 2023" name="password" className="form-control input_box " /> */}
                          <DatePicker

                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            selected={activityData.startDate}
                            value={activityData.startDate}
                            minDate={moment().toDate()}
                            onChange={(date) => {
                              setStartDate(date);
                              errorActivityHandler(
                                activityData,
                                date,
                                "startDate"
                              );
                              updateActivityData({
                                id: activityData.id,
                                billinginvoiceId: invoiceId,
                                servicecontract: activityData.servicecontract,
                                startDate: date,
                                endDate: activityData.endDate,
                                rate: activityData.rate,
                                quantity: activityData.quantity,
                                description: activityData.description,
                              });
                            }}
                            className="form-control input_box "
                          />
                          <img
                            src={Images.Calendar}
                            alt="LeftIcon"
                            className="BillIcon"
                          />
                        </div>
                        {/* <div>
                          {
                            activityErrors.find(
                              (err) =>
                                err.id === activityData.id &&
                                err.inputFieldName === "startDate"
                            )?.errorMessage
                          }
                        </div> */}
                      </div>
                      <div className="col-lg-2">
                        <div class="form-group mb-3">
                          <DatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            //selected={startDate}
                            selected={activityData.endDate}
                            minDate={activityData.startDate || moment().toDate()}
                            onChange={(date) => {
                              errorActivityHandler(
                                {
                                  ...activityData,
                                  endDate: date,
                                },
                                activityData.startDate,
                                "startDate"
                              );
                              updateActivityData({
                                id: activityData.id,
                                billinginvoiceId: props.id,
                                servicecontract: activityData.servicecontract,
                                startDate: activityData.startDate,
                                endDate: date,
                                rate: activityData.rate,
                                quantity: activityData.quantity,
                                description: activityData.description,
                              });
                            }}
                            className="form-control input_box "
                          />
                          <img
                            src={Images.Calendar}
                            alt="LeftIcon"
                            className="BillIcon"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div
                          class="form-group mb-3"
                          style={{
                            border: `${activityErrors.some(
                              (err) =>
                                err.id === activityData.id &&
                                err.inputFieldName === "qty"
                            )
                                ? "solid 1px red"
                                : ""
                              }`,
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Qty"
                            maxLength={3}
                            name="qty"
                            value={activityData.quantity}
                            onChange={(e) => {
                              errorActivityHandler(
                                activityData,
                                e.target.value,
                                "qty"
                              );
                              updateActivityData({
                                id: activityData.id,
                                billinginvoiceId: invoiceId,
                                servicecontract: activityData.servicecontract,
                                startDate: activityData.startDate,
                                endDate: activityData.endDate,
                                rate: activityData.rate,
                                quantity: e.target.value,
                                description: activityData.description,
                              });
                            }}
                            className="form-control input_box "
                          />
                          <img
                            src={Images.ArrowDown}
                            alt="LeftIcon"
                            className="BillIcon"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div
                          class="form-group mb-3"
                          style={{
                            border: `${activityErrors.some(
                              (err) =>
                                err.id === activityData.id &&
                                err.inputFieldName === "rate"
                            )
                                ? "solid 1px red"
                                : ""
                              }`,
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Rate"
                            name="rate"
                            maxLength={4}
                            value={activityData.rate}
                            onChange={(e) => {
                              errorActivityHandler(
                                activityData,
                                e.target.value,
                                "rate"
                              );
                              updateActivityData({
                                id: activityData.id,
                                billinginvoiceId: invoiceId,
                                servicecontract: activityData.servicecontract,
                                startDate: activityData.startDate,
                                endDate: activityData.endDate,
                                rate: e.target.value,
                                quantity: activityData.quantity,
                                description: activityData.description,
                              });
                            }}
                            className="form-control input_box "
                          />
                          <img
                            src={Images.RateIcon}
                            alt="LeftIcon"
                            className="BillIcon"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div
                          class="form-group mb-3"
                          style={{
                            border: `${activityErrors.some(
                              (err) =>
                                err.id === activityData.id &&
                                err.inputFieldName === "description"
                            )
                                ? "solid 1px red"
                                : ""
                              }`,
                          }}
                        >
                          <textarea
                            onChange={(e) => {
                              errorActivityHandler(
                                activityData,
                                e.target.value,
                                "description"
                              );
                              updateActivityData({
                                id: activityData.id,
                                billinginvoiceId: invoiceId,
                                servicecontract: activityData.servicecontract,
                                startDate: activityData.startDate,
                                endDate: activityData.endDate,
                                rate: activityData.rate,
                                quantity: activityData.quantity,
                                description: e.target.value,
                              });
                            }}
                            value={activityData.description}
                            className="form-control input_box"
                            placeholder="Enter Description..."
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <button
              className="AddMoreBtn"
              type="button"
              onClick={addMoreHandler}
            >
              <img
                src={Images.AddMore}
                alt="LeftIcon"
                className="AddMoreIcon"
              />
              Add More
            </button>

            <div className="TotalBillDetail">
              <div className="SubTotal">
                <span className="SubTotalText">Subtotal</span>
                <p className="SubTotalText">${sumWithInitial}</p>
              </div>
            </div>

            <div className="SubTotal">
              <span className="SubTotalText">HST (ON) @13%</span>
              <span className="SubTotalText">
                ${(sumWithInitial * 13) / 100}
              </span>
            </div>

            <div className="TotalBill">
              <span className="SubTotalText">Total Billing Amount</span>
              <p className="totalBill">
                ${sumWithInitial + (sumWithInitial * 13) / 100}
              </p>
            </div>
          </div>
        )}

      </div>
    </>
  );
};

export default CreateBill;
