import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userInfo: {},
  putUserInfo:{},
  loading: false
}

export const userSlice = createSlice({

  name: 'user',
  initialState,
  reducers: {
    getUserInfo: (state) => {
      state.loading = true
    },
    setUserInfo: (state, action) => {
      state.loading = false
      state.userInfo = action.payload
    },
    putUserInfo: (state) => {
      state.loading = true
    },
    setPutUserInfo: (state, action) => {
      state.loading = false
      state.putUserInfo = action.payload
    },
    postUserProfilePic: (state) => {
      state.loading = true
    },
    setpostUserProfilePic: (state, action) => {
      state.loading = false
      state.userInfo = action.payload
    },
    postUserInfo: (state) => {
      state.loading = true
    },
    setPostUserInfo: (state, action) => {
      state.loading = false
      state.userInfo = action.payload // saga1 can also yield [ fork(actionOne), fork(actionTwo) ]
      // fork(saga2)
    },
    patchUserInfo: (state) => {
      state.loading = true
    },
    setPatchUserInfo: (state, action) => {
      state.loading = false
      state.userInfo = action.payload
    },
    removePicture:(state)=>{
      state.loading=true
    },
    setRemovePicture:(state,action)=>{
      state.loading=false
      state.userInfo=action.payload
    },
    deleteUserInfo: (state) => {
      state.loading = true
    },
    setDeleteUserInfo: (state, action) => {
      state.loading = false
      state.userInfo = action.payload
    },
    logout: (state) => {
      state.loading = true
  },
  setLogout: (state, action) => {
      state.loading = false
      state.userInfo = {}
      state.userStep = {}
      state.userSignUp = {}
      state.isLoggedIn = false
  },
  },
})

// Action creators are generated for each case reducer function
export const { setUserInfo, getUserInfo, putUserInfo, logout,setLogout,setPutUserInfo, postUserInfo, setPostUserInfo, postUserProfilePic,setpostUserProfilePic, setRemovePicture,patchUserInfo, setPatchUserInfo, deleteUserInfo, setDeleteUserInfo } = userSlice.actions

export default userSlice.reducer