import axios from "axios";
import BaseUrl from "../constants/baseUrl";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
    baseURL: BaseUrl.API_URL,
    headers: {
        Accept: "application/json"
    }
});

//axios.defaults.headers.common['Authorization'] = localStorage.getItem('token') 

// Set the AUTH token for any request
axiosInstance.interceptors.request.use(function (config) {
    // const isRememberMe = localStorage.getItem("hasRememberMeChecked");
    let token = "";
    // if (isRememberMe?.toLowerCase?.() === 'true') {
        token = localStorage.getItem('authToken');
    //   } else {
        // token = sessionStorage.getItem('authToken');
    //   }
   
    config.headers.Authorization = token ? token : '';
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
      // Handle successful responses
      return response;
    },
    (error) => {
      // Handle response errors
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('persist:root');
        localStorage.removeItem('authToken');
        toast.warning('Session expired, Please login again')
        
        setTimeout(()=>{
            window.location.reload()
        },1000)

      }
      return Promise.reject(error);
    }
  );

const axiosGet = (url, params = {}) => {
 return axiosInstance.get(url, params)
        .then(response => {
            return { status: response.status, data: response.data }
        })
        .catch(err => {
           console.log(err,'err')
            throw err;
        });
};





const axiosPut = (url, params = {}) => {

    return axiosInstance.put(url, params)
        .then(response => {
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            console.log(err,'err')
             throw err;
         });
    
};

const axiosPost = (url, params = {}) => {

    return axiosInstance.post(url, params)
        .then(response => {
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            console.log(err,'err')
             throw err;
         });
};

const axiosPatch = (url, params = {}) => {

    return axiosInstance.patch(url, params)
        .then(response => {
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            console.log(err,'err')
             throw err;
         });
}

const postDelete = (url, params = {}) => {
    return axiosInstance.delete(url, params)
        .then(response => {
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            console.log(err,'err')
             throw err;
         });

}

const axiosPostFormData = (url, params) => {
    if(params.document){
        var formData = new FormData();
        formData.append('document', params?.document);
    }
    else{
        var formData = new FormData();
        formData.append('profile_image', params?.profile_image);
    }
    return axiosInstance.post(url, formData)
        .then(response => {
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            console.log(err,'err')
             throw err;
         });
};


export const ApiClient = {
    get: axiosGet,
    put: axiosPut,
    post: axiosPost,
    patch: axiosPatch,
    delete: postDelete,
    postFormData: axiosPostFormData,
    // deleteWithData:deleteWithData
}

