import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userInfo: {},
    companyListType : {},
    userStep:{},
    putUserInfo:{},
    editProfile:{},
    isLoggedIn: false,
    loading: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: ({
        userLogin: (state) => {
            state.loading = true
        },
        setUserLogin: (state, action) => {
            state.loading = false
            state.isLoggedIn = true
            state.userInfo = action.payload
            state.userStep = action.payload
        
        },
        
        userOtp: (state) => {
            state.loading = true
            
        },
        setUserOtp: (state, action) => {
            state.loading = false
            state.isLoggedIn = true
            state.userInfo = action.payload
            state.userStep = action.payload
        },
        forgotPass:(state)=>{
            state.loading = true
        },
        setforgotPass:(state,action)=>{
            state.loading = false
            state.isLoggedIn = true
            //state.userInfo = action.payload
            //state.userStep = action.payload
        },
        resendOtp:(state)=>{
            state.loading = true
        },
        setResendOtp:(state,action)=>{
            state.loading = false
            state.isLoggedIn = true
            //state.userInfo = action.payload
            //state.userStep = action.payload
        },
        recoverPass:(state)=>{
            state.loading=true
        },
        setrecoverPass:(state,action)=>{
            state.loading = false
            state.isLoggedIn = true
            //state.userInfo = action.payload
            //state.userStep = action.payload
        },
        changePassword:(state)=>{
            state.loading=true
        },
        setChangePassword:(state,action)=>{
            state.loading = false
            state.isLoggedIn = true
            //state.userInfo = action.payload
            //state.userStep = action.payload
        },
        
      
        logout: (state) => {
            state.loading = true
        },
        setLogout: (state, action) => {
            state.loading = false
            state.userInfo = {}
            state.userStep = {}
            state.userSignUp = {}
            state.isLoggedIn = false
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        },
        getUserInfo: (state) => {
            state.loading = true
          },
          setUserInfo: (state, action) => {
            state.loading = false
            state.userInfo = action.payload
          },
          putUserInfo: (state) => {
            state.loading = true
          },
          setPutUserInfo: (state, action) => {
            state.loading = false
            state.userInfo = {
              ...state.userInfo,
              ...action.payload
          }
          },
          postUserProfilePic: (state) => {
            state.loading = true
          },
          setpostUserProfilePic: (state, action) => {
            state.loading = false
            state.userProfilePic = action.payload
          },
          postUserInfo: (state) => {
            state.loading = true
          },
          setPostUserInfo: (state, action) => {
            state.loading = false
            state.userInfo = action.payload
          },
          patchUserInfo: (state) => {
            state.loading = true
          },
          setPatchUserInfo: (state, action) => {
            state.loading = false
            state.userInfo = action.payload
          },
          deleteUserInfo: (state) => {
            state.loading = true
          },
          setDeleteUserInfo: (state, action) => {
            state.loading = false
            state.userInfo = action.payload
          },
    }),
})

// Action creators are generated for each case reducer function
export const { userLogin, setUserLogin, userOtp, setUserOtp, forgotPass,setforgotPass, recoverPass,setrecoverPass,resendOtp,setResendOtp,changePassword,setChangePassword,onErrorStopLoad,setUserInfo, getUserInfo, putUserInfo, logout,setLogout,setPutUserInfo, postUserInfo, setPostUserInfo, postUserProfilePic,setpostUserProfilePic, deleteUserInfo, setDeleteUserInfo  } = authSlice.actions

export default authSlice.reducer