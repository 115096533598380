import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { dashboardSlice } from "./dashboard";
import { userSlice } from "./user";

export const mainReducer = combineReducers({
    user: userSlice.reducer,
    auth: authSlice.reducer,
    dashboard: dashboardSlice.reducer,
  
})