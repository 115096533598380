import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  deleteActivity,
  updateActivity,
  getBillingInvoiceById,
} from "../../../redux/slices/dashboard";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import swal from "sweetalert";
const EditBillingDetails = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [customerDetail, setGetCustomerDetail] = useState([]);
  const [activityErrors, setActivityErrors] = useState([]);
  const [invoiceActivity, setInvoiceActivity] = useState([]);
  const [updatedInvoiceActivites, setUpdatedInvoiceActivites] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCutomerData = () => {
    let params = {
      id: props.id,
    };
    dispatch(
      getBillingInvoiceById({
        ...params,
        cb(res) {
          if (res?.data) {
            const responsePayload = res?.data?.payload;
            setGetCustomerDetail(responsePayload);
            const updatedInvoiceActivityResponse =
              responsePayload?.Billinvoice?.activity_details?.map((data) => {
                return {
                  id: data.id,
                  billing_invoice_id: data.billing_invoice_id,
                  startDate: new Date(data?.start_date),
                  endDate: new Date(data?.end_date),
                  servicecontract: data.service_contract,
                  quantity: data.quantity,
                  rate: data.rate,
                  description: data.discription,
                };
              });
            setUpdatedInvoiceActivites(updatedInvoiceActivityResponse);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    getCutomerData();
  }, []);

  const updateSingleInvoiceActivity = (updatedActivityObj) => {
    const index = updatedInvoiceActivites.findIndex(
      (x) => x.id === updatedActivityObj.id
    );
    const updatedInvoiceActivitesCopy = [...updatedInvoiceActivites];
    if (index >= 0) {
      updatedInvoiceActivitesCopy[index] = updatedActivityObj;
      setUpdatedInvoiceActivites(updatedInvoiceActivitesCopy);
    }
  };

  const updateActivityData = (activityObj) => {
    const index = invoiceActivity.findIndex((x) => x.id === activityObj.id);

    const invoiceActivityCopy = [...invoiceActivity];
    if (index >= 0) {
      // invoiceActivityCopy.splice(index, 1);
      invoiceActivityCopy[index] = activityObj;
      // const updatedInvoiceActivity = [...invoiceActivityCopy, activityObj];
      setInvoiceActivity(invoiceActivityCopy);
      props.updateFormData(invoiceActivityCopy);
    }
  };

  const errorActivityHandler = (activityObj, inputValue, inputProperty) => {
    const numbers = /^[0-9]+$/;
    const rateRegx = /^[1-9]\d*(\.\d+)?$/;

    const index = activityErrors.findIndex(
      (x) => x.id === activityObj.id && x.inputFieldName === inputProperty
    );

    const activityErrorsCopy = [...activityErrors];
    let isInvalidQty = false;
    let isInvalidRate = false;
    let isServiceContractorInValid = false;
    //let isDescriptionInValid = false;
    let starDateInValid = false;

    if (inputProperty === "startDate") {
      starDateInValid = activityObj?.endDate < inputValue;
    } else {
      isInvalidQty =
        (!inputValue.match(numbers) ||
          Number(inputValue) <= 0 ||
          Number(inputValue) > 999) &&
        inputProperty === "qty";

      isInvalidRate =
        (!inputValue.match(rateRegx) ||
          Number(inputValue) <= 0 ||
          Number(inputValue) > 9999) &&
        inputProperty === "rate";

      isServiceContractorInValid =
        (inputValue.length < 3 || inputValue.length > 100) &&
        inputProperty === "servicecontract";

      // isDescriptionInValid =
      //   Boolean(inputValue) && inputProperty === "description"
      //     ? inputValue.length < 3
      //     : false;
    }

    if (
      isInvalidQty ||
      isInvalidRate ||
      isServiceContractorInValid ||
      //isDescriptionInValid ||
      starDateInValid
    ) {
      if (index < 0) {
        const activityErrorObj = {
          id: activityObj.id,
          errorMessage: isServiceContractorInValid
            ? "Length should be between 3 to 100"
            // : isDescriptionInValid
            // ? "Length should be greater than 3"
            : starDateInValid
            ? "start Date must be less than end date"
            : "Please enter valid numeric number",
          inputFieldName: inputProperty,
        };
        activityErrorsCopy.push(activityErrorObj);
        setActivityErrors(activityErrorsCopy);
        props.updateFormErrors(activityErrorsCopy);
      }
    } else {
      if (index >= 0) {
        activityErrorsCopy.splice(index, 1);
        setActivityErrors(activityErrorsCopy);
        props.updateFormErrors(activityErrorsCopy);
      }
    }
  };

  const addMoreHandler = () => {
    const addedActivityInvoice = [
      ...invoiceActivity,
      {
        id: Math.random(),
        billing_invoice_id: null,
        startDate: new Date(),
        endDate: new Date(),
        servicecontract: "",
        quantity: null,
        rate: null,
        description: "",
      },
    ];

    setInvoiceActivity(addedActivityInvoice);
  };

  const handleCancel = (activityId) => {
    const index = invoiceActivity.findIndex((x) => x.id === activityId);
    const invoiceActivityCanceledCopy = [...invoiceActivity];

    if (index >= 0) {
      invoiceActivityCanceledCopy.splice(index, 1);
      setInvoiceActivity(invoiceActivityCanceledCopy);
      props.updateFormData(invoiceActivityCanceledCopy);

      const activityErrorsCopy = activityErrors.filter(
        (err) => err.id !== activityId
      );
      props.updateFormErrors(activityErrorsCopy);
    }
  };

  const updateHandler = (activityDataObj) => {
    const errorIndex = activityErrors.findIndex(
      (x) => x.id === activityDataObj.id
    );

    if (errorIndex >= 0) {
      toast.error("Please correct update acitvity errors");
      return;
    }

    let params = {
      id: activityDataObj.id,
      service_contract: activityDataObj.servicecontract,
      start_date: activityDataObj.startDate,
      end_date: activityDataObj.endDate,
      quantity: activityDataObj.quantity,
      rate: activityDataObj.rate,
      discription: activityDataObj.description,
    };

    dispatch(
      updateActivity({
        ...params,
        cb(res) {
          if (res?.data) {
           // props.close();
            toast.success("Activity updated sucessfully!");
            props.refreshActivity();
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const deleteHandler = (dataId) => {
    let params = {
      id: dataId,
    };
    swal({
      className: "sweetAlertDelete",
      text: "Are you sure to delete this Activity?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    }).then((isUrl) => {
      if (isUrl) {
        dispatch(
          deleteActivity({
            ...params,
            cb(res) {
              if (res?.data) {
                props.refreshActivity();
                props.close();
                //setbillingDetails(res?.data?.payload);
                toast.success("Activity deleted sucessfully!");
              } else {
                toast.error("something went wrong");
              }
            },
          })
        );
        swal("Deleted!", "Activiy has been deleted!");
      }
    });
  };

  const initialValue = 0;
  const sumWithInvoiceAcitivty = invoiceActivity.reduce(
    (accumulator, currentValue) =>
      accumulator + Number(currentValue.rate) * Number(currentValue.quantity),
    initialValue
  );
  const sumWithUpdatedInvoiceActivites = updatedInvoiceActivites.reduce(
    (accumulator, currentValue) =>
      accumulator + Number(currentValue.rate) * Number(currentValue.quantity),
    initialValue
  );

  const totalSumOfActivitesCal =
    sumWithInvoiceAcitivty + sumWithUpdatedInvoiceActivites;
  const totalSumOfActivites = (
    Math.round(totalSumOfActivitesCal * 100) / 100
  ).toFixed(2);

  const subTotalWithTaxCal = (totalSumOfActivitesCal * 13) / 100;
  const subTotalWithTax = (Math.round(subTotalWithTaxCal * 100) / 100).toFixed(
    2
  );

  const totalBillingCal =
    totalSumOfActivitesCal + (totalSumOfActivitesCal * 13) / 100;
  const totalBilling = (Math.round(totalBillingCal * 100) / 100).toFixed(2);

  return (
    <>
      <div className="EditBillingDetails">
        <div class="BillingInvoice">
          <div className="d-flex">
            <h6 class="invoice_heading">
              Invoice: <span class="invoiceNumber">{props.id}</span>
            </h6>
            {/* <p class="PendingBox">Pending</p> */}
          </div>
       
        </div>
        <div className="row mt-3 BillingBox">
          <div className="col-lg-3 col-md-6">
            <h4 className="billingDetails">Bill To</h4>
            <h5 className="customerName">
              {customerDetail?.Billinvoice?.customer_name}
            </h5>
            <p className="customerAddress">
              {customerDetail?.Billinvoice?.bill_to?.Line1}
              {customerDetail?.Billinvoice?.bill_to?.PostalCode}
            </p>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="billingDetails">Ship To</h4>
            <h5 className="customerName">
              {customerDetail?.Billinvoice?.customer_name}
            </h5>
            <p className="customerAddress">
              {customerDetail?.Billinvoice?.ship_to?.Line1}
              {customerDetail?.Billinvoice?.ship_to?.PostalCode}
            </p>
          </div>
          <div className="col-lg-2 col-md-6">
            <h4 className="billingDetails">Billing Type</h4>
            <h5 className="customerName">
              {customerDetail?.Billinvoice?.billing_type_details?.name}
            </h5>
            <p className="customerAddress">
              {moment(
                customerDetail?.Billinvoice?.billing_type_details
                  ?.billing_start_date
              ).utc().format("YYYY-MM-DD")}
            </p>
          </div>
          <div className="col-lg-2 col-md-6">
            <h4 className="billingDetails">Sent Date</h4>
            <h5 className="customerName">
              {moment(customerDetail?.Billinvoice?.send_date).utc().format(
                "YYYY-MM-DD"
              )}{" "}
            </h5>
          </div>
          <div className="col-lg-2 col-md-6">
            <h4 className="billingDetails">Due Date</h4>
            <h5 className="customerName">
              {moment(customerDetail?.Billinvoice?.due_date).utc().format(
                "YYYY-MM-DD"
              )}
            </h5>
          </div>
        </div>
        {updatedInvoiceActivites?.map((activityData, index) => {
          return (
            <div key={activityData.id} className="ActivityMain mt-3">
              <h6 className="activityHeading">Updated Activity</h6>
              <div className="ActivityBox">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div
                      class="form-group mb-3"
                      style={{
                        border: `${
                          activityErrors.some(
                            (err) =>
                              err.id === activityData.id &&
                              err.inputFieldName === "servicecontract"
                          )
                            ? "solid 1px red"
                            : ""
                        }`,
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Enter Something..."
                        value={activityData.servicecontract}
                        className="form-control input_box "
                        onChange={(e) => {
                          errorActivityHandler(
                            activityData,
                            e.target.value,
                            "servicecontract"
                          );
                          updateSingleInvoiceActivity({
                            id: activityData.id,
                            billinginvoiceId: props.id,
                            servicecontract: e.target.value,
                            startDate: activityData.startDate,
                            endDate: activityData.endDate,
                            rate: activityData.rate,
                            quantity: activityData.quantity,
                            description: activityData.description,
                          });
                        }}
                      />
                      <img
                        src={Images.ArrowDown}
                        alt="LeftIcon"
                        className="BillIcon"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6">
                    <div
                      class="form-group mb-3"
                      style={{
                        border: `${
                          activityErrors.some(
                            (err) =>
                              err.id === activityData.id &&
                              err.inputFieldName === "startDate"
                          )
                            ? "solid 1px red"
                            : ""
                        }`,
                      }}
                    >
                      {/* <input type="Date" placeholder="1 March, 2023" name="password" className="form-control input_box " /> */}
                      <DatePicker
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        selected={activityData.startDate}
                        value={activityData.startDate}
                        minDate={moment().toDate()}
                        onChange={(date) => {
                          errorActivityHandler(activityData, date, "startDate");
                          updateSingleInvoiceActivity({
                            id: activityData.id,
                            billinginvoiceId: props.id,
                            servicecontract: activityData.servicecontract,
                            startDate: date,
                            endDate: activityData.endDate,
                            rate: activityData.rate,
                            quantity: activityData.quantity,
                            description: activityData.description,
                          });
                        }}
                        className="form-control input_box "
                      />
                      <img
                        src={Images.Calendar}
                        alt="LeftIcon"
                        className="BillIcon"
                      />
                    </div>
                    <div>
                      {/* {
                        activityErrors.find(
                          (err) =>
                            err.id === activityData.id &&
                            err.inputFieldName === "startDate"
                        )?.errorMessage
                      } */}
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6">
                    <div class="form-group mb-3">
                      <DatePicker
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        //selected={startDate}
                        selected={activityData.endDate}
                        minDate={activityData.startDate || moment().toDate()}
                        onChange={(date) => {
                          errorActivityHandler(
                            {
                              ...activityData,
                              endDate: date,
                            },
                            activityData.startDate,
                            "startDate"
                          );
                          updateSingleInvoiceActivity({
                            id: activityData.id,
                            billinginvoiceId: props.id,
                            servicecontract: activityData.servicecontract,
                            startDate: activityData.startDate,
                            endDate: date,
                            rate: activityData.rate,
                            quantity: activityData.quantity,
                            description: activityData.description,
                          });
                        }}
                        className="form-control input_box "
                      />
                      <img
                        src={Images.Calendar}
                        alt="LeftIcon"
                        className="BillIcon"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6">
                    <div
                      class="form-group mb-3"
                      style={{
                        border: `${
                          activityErrors.some(
                            (err) =>
                              err.id === activityData.id &&
                              err.inputFieldName === "qty"
                          )
                            ? "solid 1px red"
                            : ""
                        }`,
                      }}
                    >
                      <input
                        type="text"
                        maxLength={3}
                        placeholder="Qty"
                        name="qty"
                        value={activityData.quantity}
                        onChange={(e) => {
                          errorActivityHandler(
                            activityData,
                            e.target.value,
                            "qty"
                          );
                          updateSingleInvoiceActivity({
                            id: activityData.id,
                            billinginvoiceId: props.id,
                            servicecontract: activityData.servicecontract,
                            startDate: activityData.startDate,
                            endDate: activityData.endDate,
                            rate: activityData.rate,
                            quantity: e.target.value,
                            description: activityData.description,
                          });
                        }}
                        className="form-control input_box "
                      />
                      <img
                        src={Images.ArrowDown}
                        alt="LeftIcon"
                        className="BillIcon"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6">
                    <div
                      class="form-group mb-3"
                      style={{
                        border: `${
                          activityErrors.some(
                            (err) =>
                              err.id === activityData.id &&
                              err.inputFieldName === "rate"
                          )
                            ? "solid 1px red"
                            : ""
                        }`,
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Rate"
                        name="rate"
                        maxLength={4}
                        value={activityData.rate}
                        onChange={(e) => {
                          errorActivityHandler(
                            activityData,
                            e.target.value,
                            "rate"
                          );
                          updateSingleInvoiceActivity({
                            id: activityData.id,
                            billinginvoiceId: props.id,
                            servicecontract: activityData.servicecontract,
                            startDate: activityData.startDate,
                            endDate: activityData.endDate,
                            rate: e.target.value,
                            quantity: activityData.quantity,
                            description: activityData.description,
                          });
                        }}
                        className="form-control input_box "
                      />
                      <img
                        src={Images.RateIcon}
                        alt="LeftIcon"
                        className="BillIcon"
                      />
                    </div>
                  </div>
                  <div className="col-lg-1 col-md-6">
                    <button
                      className="AddMoreBtn"
                      type="button"
                      onClick={() => updateHandler(activityData)}
                    >
                      <i
                        class="fa fa-pencil-square-o billingIcon"
                        aria-hidden="true"
                      ></i>
                    </button>

                    <button
                      className="AddMoreBtn"
                      type="button"
                      onClick={() => deleteHandler(activityData.id)}
                    >
                      <i
                        class="fa fa-trash-o billingIcon"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                  <div className="col-lg-12">
                    <div
                      class="form-group mb-3"
                      style={{
                        border: `${
                          activityErrors.some(
                            (err) =>
                              err.id === activityData.id &&
                              err.inputFieldName === "description"
                          )
                            ? "solid 1px red"
                            : ""
                        }`,
                      }}
                    >
                      <textarea
                        onChange={(e) => {
                          errorActivityHandler(
                            activityData,
                            e.target.value,
                            "description"
                          );
                          updateSingleInvoiceActivity({
                            id: activityData.id,
                            billinginvoiceId: props.id,
                            servicecontract: activityData.servicecontract,
                            startDate: activityData.startDate,
                            endDate: activityData.endDate,
                            rate: activityData.rate,
                            quantity: activityData.quantity,
                            description: e.target.value,
                          });
                        }}
                        value={activityData.description}
                        className="form-control input_box"
                        placeholder="Enter Description..."
                      >
                      
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {invoiceActivity.map((activityData, index) => {
          return (
            <div key={activityData.id} className="ActivityMain mt-3">
              <h6 className="activityHeading">Activity</h6>
              <div className="text-end">
                <img
                  src={Images.CanceIcon}
                  alt="LeftIcon"
                  className="CancelActivity"
                  onClick={() => handleCancel(activityData.id)}
                />
              </div>
              <div className="ActivityBox">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div
                      class="form-group mb-3"
                      style={{
                        border: `${
                          activityErrors.some(
                            (err) =>
                              err.id === activityData.id &&
                              err.inputFieldName === "servicecontract"
                          )
                            ? "solid 1px red"
                            : ""
                        }`,
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Enter Something..."
                        value={activityData.servicecontract}
                        className="form-control input_box "
                        onChange={(e) => {
                          errorActivityHandler(
                            activityData,
                            e.target.value,
                            "servicecontract"
                          );
                          updateActivityData({
                            id: activityData.id,
                            billinginvoiceId: props.id,
                            servicecontract: e.target.value,
                            startDate: activityData.startDate,
                            endDate: activityData.endDate,
                            rate: activityData.rate,
                            quantity: activityData.quantity,
                            description: activityData.description,
                          });
                        }}
                      />
                      <img
                        src={Images.ArrowDown}
                        alt="LeftIcon"
                        className="BillIcon"
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-2 col-md-6"
                    style={{
                      border: `${
                        activityErrors.some(
                          (err) =>
                            err.id === activityData.id &&
                            err.inputFieldName === "startDate"
                        )
                          ? "solid 1px red"
                          : ""
                      }`,
                    }}
                  >
                    <div class="form-group mb-3">
                      <DatePicker
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        selected={activityData.startDate}
                        value={activityData.startDate}
                        minDate={moment().toDate()}
                        onChange={(date) => {
                          errorActivityHandler(activityData, date, "startDate");
                          updateActivityData({
                            id: activityData.id,
                            billinginvoiceId: props.id,
                            servicecontract: activityData.servicecontract,
                            startDate: date,
                            endDate: activityData.endDate,
                            rate: activityData.rate,
                            quantity: activityData.quantity,
                            description: activityData.description,
                          });
                        }}
                        className="form-control input_box "
                      />
                      <img
                        src={Images.Calendar}
                        alt="LeftIcon"
                        className="BillIcon"
                      />
                    </div>
                    {/* <div>
                      {
                        activityErrors.find(
                          (err) =>
                            err.id === activityData.id &&
                            err.inputFieldName === "startDate"
                        )?.errorMessage
                      }
                    </div> */}
                  </div>
                  <div className="col-lg-2 col-md-6">
                    <div class="form-group mb-3">
                      <DatePicker
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        //selected={startDate}
                        selected={activityData.endDate}
                        minDate={activityData.startDate || moment().toDate()}
                        onChange={(date) => {
                          errorActivityHandler(
                            {
                              ...activityData,
                              endDate: date,
                            },
                            activityData.startDate,
                            "startDate"
                          );
                          updateActivityData({
                            id: activityData.id,
                            billinginvoiceId: props.id,
                            servicecontract: activityData.servicecontract,
                            startDate: activityData.startDate,
                            endDate: date,
                            rate: activityData.rate,
                            quantity: activityData.quantity,
                            description: activityData.description,
                          });
                        }}
                        className="form-control input_box "
                      />
                      <img
                        src={Images.Calendar}
                        alt="LeftIcon"
                        className="BillIcon"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6">
                    <div
                      class="form-group mb-3"
                      style={{
                        border: `${
                          activityErrors.some(
                            (err) =>
                              err.id === activityData.id &&
                              err.inputFieldName === "qty"
                          )
                            ? "solid 1px red"
                            : ""
                        }`,
                      }}
                    >
                      <input
                        type="text"
                        maxLength={3}
                        placeholder="Qty"
                        name="qty"
                        value={activityData.quantity}
                        onChange={(e) => {
                          errorActivityHandler(
                            activityData,
                            e.target.value,
                            "qty"
                          );
                          updateActivityData({
                            id: activityData.id,
                            billinginvoiceId: props.id,
                            servicecontract: activityData.servicecontract,
                            startDate: activityData.startDate,
                            endDate: activityData.endDate,
                            rate: activityData.rate,
                            quantity: e.target.value,
                            description: activityData.description,
                          });
                        }}
                        className="form-control input_box "
                      />
                      <img
                        src={Images.ArrowDown}
                        alt="LeftIcon"
                        className="BillIcon"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6">
                    <div
                      class="form-group mb-3"
                      style={{
                        border: `${
                          activityErrors.some(
                            (err) =>
                              err.id === activityData.id &&
                              err.inputFieldName === "rate"
                          )
                            ? "solid 1px red"
                            : ""
                        }`,
                      }}
                    >
                      <input
                        type="text"
                        maxLength={4}
                        placeholder="Rate"
                        name="rate"
                        value={activityData.rate}
                        onChange={(e) => {
                          errorActivityHandler(
                            activityData,
                            e.target.value,
                            "rate"
                          );
                          updateActivityData({
                            id: activityData.id,
                            billinginvoiceId: props.id,
                            servicecontract: activityData.servicecontract,
                            startDate: activityData.startDate,
                            endDate: activityData.endDate,
                            rate: e.target.value,
                            quantity: activityData.quantity,
                            description: activityData.description,
                          });
                        }}
                        className="form-control input_box "
                      />
                      <img
                        src={Images.RateIcon}
                        alt="LeftIcon"
                        className="BillIcon"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      class="form-group mb-3"
                      style={{
                        border: `${
                          activityErrors.some(
                            (err) =>
                              err.id === activityData.id &&
                              err.inputFieldName === "description"
                          )
                            ? "solid 1px red"
                            : ""
                        }`,
                      }}
                    >
                      <textarea
                        onChange={(e) => {
                          errorActivityHandler(
                            activityData,
                            e.target.value,
                            "description"
                          );
                          updateActivityData({
                            id: activityData.id,
                            billinginvoiceId: props.id,
                            servicecontract: activityData.servicecontract,
                            startDate: activityData.startDate,
                            endDate: activityData.endDate,
                            rate: activityData.rate,
                            quantity: activityData.quantity,
                            description: e.target.value,
                          });
                        }}
                        value={activityData.description}
                        className="form-control input_box"
                        placeholder="Enter Description..."
                      ></textarea>
                    </div>
                    <div>
                      {/* {
                        activityErrors.find(
                          (err) =>
                            err.id === activityData.id &&
                            err.inputFieldName === "description"
                        )?.errorMessage
                      } */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <button className="AddMoreBtn" type="button" onClick={addMoreHandler}>
          <img src={Images.AddMore} alt="LeftIcon" className="AddMoreIcon" />
          {invoiceActivity.length > 0 ? "Add More" : "Add Activity"}
        </button>
        {/* <div className='TotalBillDetail'>
          <div className='SubTotal'>
            <span className='SubTotalText'>HST (ON) @{getCustomerDetail?.tax}%</span>
            <span className='SubTotalText'>${getCustomerDetail?.total_tax}</span>
          </div>
        </div>

        <div className='SubTotal'>
          <span className='SubTotalText'>Subtotal</span>
          <p className='SubTotalText'>${getCustomerDetail?.sub_total}</p>
          </div>

          <div className='TotalBill'>
            <span className='SubTotalText'>Total Billing Amount</span>
            <p className='totalBill'>${getCustomerDetail?.total_amt}</p>
          </div> */}
        <div className="TotalBillDetail">
          <div className="SubTotal">
            <span className="SubTotalText">Subtotal</span>
            <p className="SubTotalText">
              ${activityErrors.length <= 0 ? totalSumOfActivites : 0}
            </p>
          </div>
        </div>

        <div className="SubTotal">
          <span className="SubTotalText">HST (ON) @13%</span>
          <span className="SubTotalText">
            ${activityErrors.length <= 0 ? subTotalWithTax : 0}
          </span>
        </div>

        <div className="TotalBill">
          <span className="SubTotalText">Total Billing Amount</span>
          <p className="totalBill">
            ${activityErrors.length <= 0 ? totalBilling : 0}
          </p>
        </div>
      </div>
    </>
  );
};

export default EditBillingDetails;
