import React, { useState } from "react";
import * as Images from "../../../utilities/images";
import { recoverPass } from "../../../redux/slices/auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const RecoverPassword = () => {
  const [recoverPassword, setRecoverPassword] = useState({
    password: "",
    confirmPass: "",
  });
  const [errorMessages, setErrorMessages] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let user_id = location?.state?.id;

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setConfirmPasswordShown] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setRecoverPassword({ ...recoverPassword, [name]: value.trim() });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recoverPassword.password) {
      toast.error("Please enter password");
      return;
    } else if (!recoverPassword.confirmPass) {
      toast.error("Please enter confirm password");
      return;
    } else if (recoverPassword.password.length <= 7) {
      toast.error("Password must be alteast 8 character");
      return;
    } else if (recoverPassword.password !== recoverPassword.confirmPass) {
      toast.error("Password and Confirm password must match");
      return;
    }

    // else if(recoverPassword.password && /[0-9]/.test+/[A-Z]/.test(recoverPassword.password)){
    //     toast.error("Password must be atleast 1 number and 1 capital letter");
    //     return;
    //     }
    else if (
      !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
        recoverPassword.password
      )
    ) {
      toast.error(
        "Password must be atleast 1 number and 1 capital letter and 1 special charcters"
      );
      return;
    }

    let params = {
      password: recoverPassword.password,
      confirm_password: recoverPassword.confirmPass,
      user_id: "1",
    };
    dispatch(
      recoverPass({
        ...params,
        cb(res) {
          if (res.status) {
            navigate("/");
            //toast.success("Password recovered succesfully!");
          } else {
            //toast.error("Please try again later");
          }
        },
      })
    );
  };
  //   //password field show or hide
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(passwordConfirmShown ? false : true);
  };

  const isValidChars =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
      recoverPassword.password
    );
  // &&
  // /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
  //   recoverPassword.confirmPass
  // );
  const isPasswordValidLength = recoverPassword?.password?.length >= 8;
  //  &&
  // recoverPassword?.confirmPass?.length >= 8;

  return (
    <>
      <div className="login">
        <div className="row">
          <div className="col-lg-12">
            <div className="logo_icon">
              <img src={Images.logo} alt="Location" className="logo_img" />
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-7">
            <img src={Images.LoginLeft} alt="LeftIcon" className="LoginLeft" />
            <p className="copyright mainCopyright">
              © Copyright 2023 all rights reserved.
            </p>
          </div>
          <div className="col-lg-5">
            <div className="login_details">
              <h1 className="login_">Recover Password</h1>
              <p className="welcome_note mb-4">
                Create your new password here.
              </p>
              <form id="form">
                <div class="form-group mb-4">
                  <label class="input_label" for="uname">
                    Password
                  </label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    placeholder="Enter Password"
                    name="password"
                    value={recoverPassword.password}
                    onChange={(e) => handleChange(e)}
                    className="form-control input_box "
                  />
                  <img src={Images.Lock} alt="LeftIcon" className="inputIcon" />
                  <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      togglePasswordVisiblity(!passwordShown);
                    }}
                  >
                    <span
                      className={
                        passwordShown
                          ? "show-icon togglePassword"
                          : "hide-icon togglePassword"
                      }
                      id=""
                    ></span>
                  </span>
                  {/* <img src={Images.eyeIcon} alt='LeftIcon' className="eyeIcon" /> */}
                </div>
                <div class="form-group mb-4">
                  <label class="input_label" for="uname">
                    Confirm Password
                  </label>
                  <input
                    type={passwordConfirmShown ? "text" : "password"}
                    placeholder="Enter Password"
                    name="confirmPass"
                    value={recoverPassword.confirmPass}
                    onChange={(e) => handleChange(e)}
                    className="form-control input_box "
                  />
                  <img src={Images.Lock} alt="LeftIcon" className="inputIcon" />
                  <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      toggleConfirmPasswordVisiblity(!passwordConfirmShown);
                    }}
                  >
                    <span
                      className={
                        passwordConfirmShown
                          ? "show-icon togglePassword"
                          : "hide-icon togglePassword"
                      }
                      id=""
                    ></span>
                  </span>
                  {/* <img src={Images.eyeIcon} alt='LeftIcon' className="eyeIcon" /> */}
                </div>
                <span
                  className={
                    passwordShown
                      ? "show-icon togglePassword"
                      : "hide-icon togglePassword"
                  }
                  id=""
                ></span>
                <artical
                  className={
                    isPasswordValidLength
                      ? "correct_password mb-2"
                      : "incorrect_password mb-2"
                  }
                >
                  <figure>
                    {/* {
                                    isValidChars  ?
                                    <>In valid Chars</>
                                    : ""
                                } */}
                    <img
                      src={
                        isPasswordValidLength ? Images.Correct : Images.Wrong
                      }
                      alt="LeftIcon"
                      className={
                        isPasswordValidLength ? "correctIcon" : "incorrectIcon"
                      }
                    />
                  </figure>
                  <p>Must be at least 8 Characters!</p>
                </artical>
                <artical
                  className={
                    isValidChars
                      ? "correct_password mb-4"
                      : "incorrect_password mb-4"
                  }
                >
                  <figure>
                    <img
                      src={isValidChars ? Images.Correct : Images.Wrong}
                      alt="LeftIcon"
                      className={isValidChars ? "correctIcon" : "incorrectIcon"}
                    />
                  </figure>
                  <p>Must be at least 1 Number & 1 Capital Case later & 1 special character!</p>
                </artical>

                <button
                  class="submit_btn w-100"
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  <span className="submit_text">Recovery</span>
                </button>
                <p class="forget_password text-center mt-3">
                  <Link to="/">
                    <img
                      src={Images.backIcon}
                      alt="LeftIcon"
                      className="backIcon"
                    />
                    Go Back
                  </Link>
                </p>
              </form>
            </div>
            <p className="copyright mobileCopyright text-center">
              © Copyright 2023 all rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverPassword;
