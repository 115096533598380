import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Images from "../../../utilities/images";
import { userLogin } from "../../../redux/slices/auth";

import { Link } from "react-router-dom";

const Login = (props) => {

  const rememberUserEmail = localStorage.getItem("rememberUserEmail");
  const rememberPassword = localStorage.getItem("rememberPassword");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRememberme, setIsRemberme] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [formData, setFormData] = useState({
    email: rememberUserEmail,
    password: rememberPassword,
    // email: '',
    // password: '',
    role_id: 1,
  });
  const [passwordShown, setPasswordShown] = useState(false);

  //onchange input
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value.trim() });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  const handleChangeRememberMe = (e, fieldname) => {
    if (fieldname === "rememberme") {
      setIsRemberme(!isRememberme);
    }
  };

  //validation form on email password
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData.email) {
      formIsValid = false;
      errors = { ...errors, email: "Please enter your email." };
    } else if (typeof formData.email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(formData.email.trim())) {
        formIsValid = false;
        errors = { ...errors, email: "Please enter valid email." };
      }
    }
    if (!formData.password) {
      formIsValid = false;
      errors = { ...errors, password: "Please enter your password." };
    }
    setErrorMessages(errors);
    return formIsValid;
  };

  //form login
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.email) {
      toast.error("Please enter email address");
      return;
    } else if (
      formData.email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        formData.email
      )
    ) {
      toast.error("Please enter valid email address");
      return;
    } else if (!formData.password) {
      toast.error("Please enter password");
      return;
    } else if (formData.password.length <= 7) {
      toast.error("Password should be maximum 8 character");
      return;
    }
 
    if (validateForm) {
    
      localStorage.setItem("hasRememberMeChecked", Boolean(isRememberme));

      let params = {
        email: formData.email.trim(),
        password: formData.password,
        role_id: formData.role_id,
      };
      dispatch(
        userLogin({
          ...params,
          cb(res) {
            if (res.data.payload.id) {
              navigate("/dashboard");
            } else {
            }
          },
        })
      );
    }
  };
  //   //password field show or hide
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <>
      <div className="login">
        <div className="row">
          <div className="col-lg-12">
            <div className="logo_icon">
              <img src={Images.logo} alt="Location" className="logo_img" />
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-7">
            <img src={Images.LoginLeft} alt="LeftIcon" className="LoginLeft" />
            <p className="copyright mainCopyright">
              © Copyright 2023 all rights reserved.
            </p>
          </div>
          <div className="col-lg-5">
            <div className="login_details">
              <h1 className="login_">Login</h1>
              <p className="welcome_note">
                Please enter your login details below.
              </p>
              <form onSubmit={(e) => handleSubmit(e)} id="form">
                <div className="form-group mb-4">
                  <label className="input_label" for="uname">
                    Email
                  </label>
                  <input
                    type="text"
                    placeholder="Email Address"
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleChange(e)}
                    className="form-control input_box "
                  />
                  <img
                    src={Images.Message}
                    alt="LeftIcon"
                    className="inputIcon"
                  />
                </div>
                <div class="form-group mb-4">
                  <label class="input_label" for="uname">
                    Password
                  </label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    placeholder="Enter Password"
                    name="password"
                    value={formData.password}
                    onChange={(e) => handleChange(e)}
                    className="form-control input_box "
                  />
                  <img src={Images.Lock} alt="LeftIcon" className="inputIcon" />
                  <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      togglePasswordVisiblity(!passwordShown);
                    }}
                  >
                    <span
                      className={
                        passwordShown
                          ? "show-icon togglePassword"
                          : "hide-icon togglePassword"
                      }
                      id=""
                    ></span>
                  </span>
                </div>

                <div className="form-group mb-4">
                  <input
                    type="checkbox"
                    id="remember"
                    name=""
                    onChange={(e) => {
                      handleChangeRememberMe(e, "rememberme");
                    }}
                    value="1"
                    class="remember_me form-check-input"
                  />
                  <label class="input_label" for="RememberMe">
                    {" "}
                    Remember Me{" "}
                  </label>
                </div>
                <button class="submit_btn w-100" type="submit">
                  <span className="submit_text">Login</span>
                </button>
                <p class="forget_password text-center  pt-3">
                  <Link to="/forgotpassword">Forgot Password?</Link>
                </p>
              </form>
            </div>
            <p className="copyright mobileCopyright text-center">
              © Copyright 2023 all rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
