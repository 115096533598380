import { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import "react-datepicker/dist/react-datepicker.css";
import {
  getBillingInvoiceById,
  createManuallInvoice,
} from "../../../redux/slices/dashboard";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

const BillingDetails = (props) => {
  const [customerDetailData, setGetCustomerData] = useState();
  const [isPushInvoiceButtonshow, setIsPushInvoiceButtonshow] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const getCutomerData = () => {
    let params = {
      id: props?.id,
    };
    dispatch(
      getBillingInvoiceById({
        ...params,
        cb(res) {
          if (res?.data) {
            setGetCustomerData(res?.data?.payload);
            let status = res?.data?.payload?.Billinvoice?.status;
            props.setCustomerStatusFun(status)
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    getCutomerData();
    //getAllActivity();
  }, []);

  const handlePushInvoice = () => {
    let params = {
      customer_id: props?.customerId,
      billing_invoice_id: props?.id,
    };
    dispatch(
      createManuallInvoice({
        ...params,
        cb(res) {
          if (res.status) {
            setIsPushInvoiceButtonshow(false);
            getCutomerData();
            props.refreshPage()
            //navigate("/dashboard");
          } else {
            //navigate(`/autobill/${customerId}`);
          }
        },
      })
    );
  };

  return (
    <>
      <div class="BillingInvoice">
        <div className="d-flex">
          <h6 class="invoice_heading">
            Invoice: <span class="invoiceNumber">{props.id}</span>
          </h6>

          <p>
            {customerDetailData?.Billinvoice?.status === "send" ? (
              <p className="sentBox">
                {customerDetailData?.Billinvoice?.status}
              </p>
            ) : (
              <p className="PendingBox">
                {customerDetailData?.Billinvoice?.status}
              </p>
            )}
          </p>
          {/* <p class="PendingBox">{getCustomerDetail?.Billinvoice?.status}</p> */}
        </div>
      </div>
      <div className="row mt-3 BillingBox">
        <div className="col-lg-3">
          <h4 className="billingDetails">Bill To</h4>
          <h5 className="customerName">
            {customerDetailData?.Billinvoice?.customer_name}
          </h5>
          <p className="customerAddress">
          {customerDetailData?.Billinvoice?.bill_to?.City}
          </p>
          <p className="customerAddress">
            {customerDetailData?.Billinvoice?.bill_to?.Line1}
            </p>
            <p className="customerAddress"> 
            {customerDetailData?.Billinvoice?.bill_to?.PostalCode}
          </p>
          <p className="customerAddress">
          {customerDetailData?.Billinvoice?.bill_to?.CountrySubDivisionCode}
          </p>
        </div>
        <div className="col-lg-3">
          <h4 className="billingDetails">Ship To</h4>
          <h5 className="customerName">
            {customerDetailData?.Billinvoice?.customer_name}
          </h5>
          <p className="customerAddress">
          {customerDetailData?.Billinvoice?.bill_to?.City}
          </p>
          <p className="customerAddress">
            {customerDetailData?.Billinvoice?.ship_to?.Line1}
            {customerDetailData?.Billinvoice?.ship_to?.PostalCode}
          </p>
          <p className="customerAddress">
          {customerDetailData?.Billinvoice?.bill_to?.CountrySubDivisionCode}
          </p>
        </div>
        <div className="col-lg-2">
          <h4 className="billingDetails">Billing Type</h4>
          <h5 className="customerName">
            {customerDetailData?.Billinvoice?.billing_type_details?.name}
          </h5>
          <p className="customerAddress">
            {moment(
              customerDetailData?.Billinvoice?.billing_type_details
                ?.billing_start_date
            ).utc().format("YYYY-MM-DD")}
          </p>
        </div>
        <div className="col-lg-2">
          <h4 className="billingDetails">Sent Date</h4>
          <h5 className="customerName input_box">
            {moment(customerDetailData?.Billinvoice?.send_date).utc().format(
              "YYYY-MM-DD"
            )}{" "}
          </h5>
        </div>
        <div className="col-lg-2">
          <h4 className="billingDetails">Due Date</h4>
          <h5 className="customerName input_box">
            {moment(customerDetailData?.Billinvoice?.due_date).utc().format(
              "YYYY-MM-DD"
            )}
          </h5>
        </div>
      </div>
      <div className="table-responsive mt-3">
        <table id="automaticBilling" className="automatic_Billing">
          <thead>
            <tr className="billingBox">
              <th className="headingCustomer">Activity </th>
              <th className="headingCustomer">Qty</th>
              <th className="headingCustomer">Rate</th>
              <th className="headingCustomer">Amount</th>
            </tr>
          </thead>
          <tbody>
            {customerDetailData?.Billinvoice?.activity_details?.map(
              (data, index) => {
                return (
                  <>
                    <tr className="activities_user ">
                      <td className="subheadingCustomer">
                        <img
                          src={Images.ActivityIcon}
                          alt="LeftIcon"
                          className="bikkdetailsIcon"
                        />
                        <div className="">
                          <p>
                            <img src={Images.Calendar} alt="LeftIcon" className="" />
                            <span className="billingDate">{moment(data?.start_date).format('D MMM, YYYY')} - {moment(data?.end_date).format('D MMM, YYYY')}</span>
                          </p>
                          <h6 className="billingHeading">
                            {data?.service_contract}
                          </h6>
                          <p className="billingSubheading">
                            {data?.discription}
                          </p>
                        </div>
                      </td>
                      <td className="subheadingCustomer">{data?.quantity}</td>
                      <td className="subheadingCustomer">{data?.rate}</td>
                      <td className="subheadingCustomer">{data?.total_rate}</td>
                    </tr>
                  </>
                );
              }
            )}
          </tbody>
        </table>
        <div className="TotalBillDetail">
          <div className="SubTotal">
            <span className="SubTotalText">Subtotal</span>
            <p className="SubTotalText">${customerDetailData?.sub_total}</p>
          </div>
        </div>

        <div className="SubTotal">
          <span className="SubTotalText">
            HST (ON) @{customerDetailData?.tax}%
          </span>
          <span className="SubTotalText">${customerDetailData?.total_tax}</span>
        </div>

        <div className="TotalBill">
          <span className="SubTotalText">Total Billing Amount</span>
          <p className="totalBill">${customerDetailData?.total_amt}</p>
        </div>
        {isPushInvoiceButtonshow &&
          customerDetailData?.Billinvoice?.activity_details?.length > 0 &&
          Boolean(props.isManualBilling) && customerDetailData?.Billinvoice?.status !== "send" ? (
          <div className="modal_btn w-50 text-center">
            <button
              class="submit_btn w-50 text-center"
              type="submit"
              onClick={handlePushInvoice}
            >
              <span className="submit_text">Push Invoice</span>
            </button>
          </div>
        ) : (
          ""
        )}

      </div>
    </>
  );
};

export default BillingDetails;
